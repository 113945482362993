<template>
  <div>
    <div>
      <filterSearch
        ref="filter"
        class="gas_order_filter"
        :data="filterData"
        @serach="search"
      />
    </div>
    <vueTable
      ref="table"
      :headers="headers"
      :rows="datas"
      :selection="false"
      :pagination="pagination"
      :isPage="true"
      :index="true"
      hasScroll
      class="table"
      @sizechange="sizechange"
    >
      <template v-slot:operations="{ row }">
        <el-button type="text" class="primary-del" @click="add(row)">
          新增
        </el-button>
      </template>
    </vueTable>
    <sysDialog
      :title="Dialog.title"
      :visible="Dialog.visible"
      :width="Dialog.width"
      :height="Dialog.height"
      :isfoot="Dialog.isfoot"
      @onClose="onClose"
    >
      <div slot="content">
        <div>
          <vueTable
            ref="table"
            :headers="Vouheaders"
            :rows="Voudatas"
            :selection="false"
            :pagination="Voupagination"
            :isPage="true"
            :index="true"
            :tableHeight="500"
            @sizechange="Vousizechange"
          >
            <template v-slot:operations="{ row }">
              <el-button type="warning"  size="mini" @click="send(row)">
                发放
              </el-button>
            </template>
          </vueTable>
        </div>
      </div>
    </sysDialog>
  </div>
</template>
<script>
//import引入的组件
import vueTable from "../../../components/table/table.vue";
import filterSearch from "../../../components/table/table_filter.vue";
import { getBScouponListApi, getBSVouListApi ,insertCoupon} from "../../../api/coupon";
import {Message} from "element-ui"
import sysDialog from "../../../components/SysDialog.vue";
export default {
  components: {
    vueTable,
    sysDialog,
    filterSearch,
  },
  data() {
    return {
      Voudatas: [],
      Vouheaders: [
        {
          prop: "id",
          label: "Id",
          width: "",
        },
        {
          prop: "name",
          label: "优惠券名称",
          width: "",
        },
        {
          prop: "taxPrice",
          label: "门槛价格",
          width: "",
        },
        {
          prop: "upPrice",
          label: "优惠价格",
          width: "",
        },
            {
          label: "操作",
          width: 220,
          type: "operations",
        },
      ],
      userId:"",
      Dialog: {
        title: "发放优惠券",
        visible: false,
        width: 1100,
        height: 600,
        isfoot: false,
      },
      Voupagination: {
        page: 1,
        rowsPerPage: 9,
        total: 0,
      },
      filterData: [
        {
          label: "用户名",
          type: "input",
          key: "nickName",
          placeholder: "请输入用户名",
        },
        {
          label: "是否使用",
          type: "select",
          key: "isUsed",
          placeholder: "请选择是否使用",
          arr: [
            {
              value: "",
              label: "全部",
            },
            {
              value: 1,
              label: "是",
            },
            {
              value: 0,
              label: "否",
            },
          ],
        },
      ],
      searchData: {
        nickName: null,
        isUsed: null,
      },
      headers: [
        {
          prop: "voucherId",
          label: "券id",
          width: "",
        },
        {
          prop: "voucherName",
          label: "券名",
          width: "",
        },
        {
          prop: "useType",
          label: "使用类型",
          width: "",
        },
        {
          prop: "createTime",
          label: "领券日期",
          width: "",
        },
        // {
        //   prop: "endTime",
        //   label: "到期时间",
        //   width: "",
        // },
        {
          prop: "taxPrice",
          label: "减免金额",
          width: "",
        },
        {
          prop: "upPrice",
          label: "需要金额",
          width: "",
        },
        {
          prop: "nickName",
          label: "用户名称",
          width: "",
        },
        {
          prop: "isUsed",
          label: "是否使用",
          width: "",
        },
        //  {
        //   prop: "drawType",
        //   label: "领取类型",
        //   width: "",
        // },
        //  {
        //   prop: "giveNum",
        //   label: "赠送数量",
        //   width: "",
        // },
        {
          label: "操作",
          width: 220,
          type: "operations",
        },
      ],
      datas: [{}],
      pagination: {
        page: 1,
        rowsPerPage: 10,
        total: 0,
      },
    };
  },
  //方法集合
  methods: {
    search(value) {
      this.searchData.nickName = value.nickName || null;
      this.searchData.isUsed = value.isUsed || null;
      (this.pagination.rowsPerPage = 10), (this.pagination.page = 1);
      this.getBScouponListApi();
    },
    //获取优惠券列表
    async getBSVouListApi(){
    let res = await getBSVouListApi(
        this.Voupagination.rowsPerPage,
        this.Voupagination.page,
        {}
      );
      if(res.data.code=="000"){
        this.Voudatas=res.data.results
         this.Voupagination.total = res.data.totalCount;
      }
    },
    async getBScouponListApi() {
      let res = await getBScouponListApi(
        this.pagination.rowsPerPage,
        this.pagination.page,
        this.searchData
      );
      if (res.data.code == "000") {
        res.data.results.forEach((item) => {
          item.isUsed == 0
            ? (item.isUsed = "否")
            : item.isUsed == 1
            ? (item.isUsed = "是")
            : (item.isUsed = "---");
          item.useType == 1
            ? (item.useType = "专家咨询业务")
            : item.useType == 2
            ? (item.useType = "课程购买")
            : item.useType == 4
            ? (item.useType = "购买测试")
            : item.useType == 5
            ? (item.useType = "倾听者咨询业务")
            : item.useType == 6
            ? (item.useType = "余额充值")
            : item.useType == 9
            ? (item.useType = "专家文字单咨询业务")
            : item.useType == 10
            ? (item.useType = "倾听者文字单咨询业务")
            : (item.useType = "---");
        });
        this.datas = res.data.results;
        this.pagination.total = res.data.totalCount;
      }
    },
    sizechange(obj) {
      this.pagination.rowsPerPage = obj.rowsPerPage;
      this.getBScouponListApi();
    },
    Vousizechange(obj) {
      this.Voupagination.rowsPerPage = obj.rowsPerPage;
      this.getBSVouListApi()
    },
    add(row) {
      this.Dialog.visible = true;
      this.userId=row.userId
      this.getBSVouListApi()
    },
     send(row) {
         this.$confirm("确定要发放该优惠券给该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async() => {
              let obj = {
              userId: this.userId,
              id: Number(row.id),
            };
            let res = await insertCoupon(obj);
            if (res.data.code == "000") {
              this.getBScouponListApi();
              this.Dialog.visible=false
            }
            Message({
              type: "success",
              message: "发放成功!",
            });
        })
        .catch(() => {
          Message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    onClose() {
      this.Dialog.visible = false;
    },
  },
  //生命周期 - 挂载前
  created() {
    this.getBScouponListApi();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang='scss' scoped>
</style>

