<template>
  <div class="lot_block">
    <div v-for="(item, index) in lot_list" :key="index" class="lot_item">
      {{ item }}
      <i class="el-icon-close lot_del" @click="dellot(index)"></i>
    </div>
    <el-input
      v-if="lotAddShow"
      class="lot_input"
      v-model="newValue"
      @keyup.enter.native="watchKeyboard"
    ></el-input>
    <el-button
      class="add_lot"
      @click="addlot('white')"
      icon="el-icon-plus"
      v-if="lot_list.length < 5"
    >增加</el-button>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => {
        [];
      },
    },
    itemkey: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    lot_list: [],
    newValue: "",
    lotAddShow: false,
  }),
  created() {},
  methods: {
    addlot(type) {
      this.lotAddShow
        ? this.$message({
            message: "请按回车完善本次ip填写",
            type: "error",
          })
        : (this.lotAddShow = true);
    },
    dellot(index) {
      this.lot_list.splice(index, 1);
    },
    watchKeyboard() {
      let self = this;
      if (self.newValue != "") {
        self.lot_list.push(self.newValue);
        self.lotAddShow = false;
        self.newValue = "";
        self.$emit("getValue", { itemkey: this.itemkey, list: self.lot_list });
      }
    },
  },
};
</script>

<style scoped>
.lot_block {
  border-radius: 17px;
  background-color: #f7f8f9;
  border: solid 1px #e3e5ef;
  padding: 0 10px 0 10px;
  line-height: 34px;
  width: calc(100%);
}
.lot_item {
  display: inline-block;
  position: relative;
  background-color: #e6ebfe;
  border-radius: 4px;
  color: #0f2683;
  font-size: 12px;
  line-height: 20px;
  padding: 0 24px 0 8px;
  margin-right: 10px;
  margin-bottom: 4px;
}
.lot_del {
  position: absolute;
  right: 8px;
  top: 3px;
  font-size: 14px;
  color: #6573ae;
  cursor: pointer;
}
.lot_del:hover {
  color: #e56600;
}
.add_lot {
  background-color: #495feb;
  border-radius: 4px !important;
  color: #f8f9fd;
  font-size: 12px;
  line-height: 20px;
  padding: 0 8px !important;
  margin-right: 10px !important;
  margin-bottom: 4px !important;
  width: 60px !important;
  height: 24px !important;
  margin-left: 0 !important;
}
.lot_input {
  width: 110px !important;
  margin-left: 0 !important;
  margin-right: 10px !important;
}
</style>
<style>
.lot_input .el-input__inner {
  height: 20px !important;
  line-height: 20px !important;
  font-size: 12px;
}
</style>
