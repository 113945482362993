<template>
<iframe :src="name" frameborder='0' style='width:100%;height:90%'></iframe>
</template>

<script>

//import引入的组件
export default {
components: {},
data() {
return {
name:"",
};
},
//方法集合
methods: {

},
//生命周期 - 挂载前
created() {
console.log(this.$route)
},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {
this.name=this.$route.name
},
}
</script>
<style lang='scss' scoped>

</style>