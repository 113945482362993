<template>
  <div ref="dd_tp" class="dd_tp">
    <!-- 数据表格 -->
    <el-table
      :data="rows"
      :size="size"
      :border="isBorder"
      element-loading-text="加载中..."
      ref="cesTable"
      v-cloak
      :row-key="rowKey"
      :tree-props="{ children: 'child' }"
      @sort-change="sortChange"
      @select="select"
      @select-all="selectAll"
      @row-click="clickTable"
      :row-class-name="tableRowClassName"
      :height="tableHeight"
    >
      <el-table-column
        type="selection"
        width="60"
        align="center"
        v-if="selection"
      ></el-table-column>
      <!-- 序号 -->
      <el-table-column
        v-if="index"
        :index="indexMethod"
        align="center"
        label="序号"
        type="index"
        width="80"
      >
      </el-table-column>
      <!-- 数据栏 -->
      <el-table-column
        v-for="(item, index) in getheaders"
        :key="'h_' + index"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
        :sortable="item.sortable"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <!-- 操作按钮 -->
          <span v-if="item.type === 'Button'">
            <b
              v-for="(btn, key) in item.btnList"
              :key="'h_' + index + '_b_' + key"
            >
              <span v-if="btn.type === 'edit'">
                <!-- audit_state: 0,1 -->
                <span
                  v-if="scope.row[item.prop] == 0"
                  class="primary-del"
                  @click="handleoperate(scope.row, btn.label, btn.option)"
                >
                  {{ btn.label }}
                </span>
                <span v-else style="color: #ffff; opacity: 0">
                  {{ btn.label }}
                </span>
              </span>
              <span v-else-if="btn.type === 'examine'">
                <span
                  v-if="scope.row[item.prop] == 0"
                  class="primary-del"
                  @click="handleoperate(scope.row, btn.label, btn.option)"
                >
                  {{ btn.label }}
                </span>
                <span v-else> </span>
              </span>

              <!-- 订单页面 -->
              <span v-else>
                <em
                  :class="btn.type"
                  @click="handleoperate(scope.row, btn.label, btn.option)"
                  >{{ btn.label }}</em
                >
                <em v-if="btn.line" style="padding: 0 20px; color: #edf0ff">{{
                  btn.line
                }}</em>
              </span>
            </b>
          </span>
          <!--href-->
          <span
            v-else-if="item.type == 'href'"
            class="hrefclass"
            @click="handlehref(scope.row)"
            >{{ scope.row[item.prop] }}</span
          >
          <!--created_at时间-->
          <span v-else-if="item.type == 'time'">{{
            helper.timestamp(scope.row[item.prop])
          }}</span>
          <!-- 用户管理  地推人员显示推广码 -->
          <span v-else-if="item.type == 'promotionCode'">
            {{
              scope.row.userRole === 20
                ? scope.row.promotionCode
                : ""
            }}
          </span>
          <span v-else-if="item.type == 'date_format'">{{
            helper.dateFormat("YYYY-mm-dd HH:MM:SS", scope.row[item.prop])
          }}</span>
          <span
            v-else-if="item.type === 'examine' && scope.row[item.prop] === 0"
            style="color: #d72c2b"
            >{{ scope.row[item.prop] }}</span
          >
          <slot
            v-else-if="item.type === 'operations'"
            name="operations"
            :row="scope.row"
            :index="scope.$index"
          ></slot>
          <!--表内input -->
          <el-input
            v-if="item.edit"
            v-model="scope.row[item.prop]"
            placeholder="请输入"
            @blur="inputValidate(scope.row)"
          ></el-input>
          <!-- 表内展示图片 -->
          <el-image
            v-if="item.type === 'image' && scope.row[item.prop] !== ''"
            style="width: 100px; height: 100px"
            :src="scope.row[item.prop]"
            :preview-src-list="[scope.row[item.prop]]"
          >
          </el-image>
          <div
            v-if="item.edit1"
          >
            <el-input class="inpBox" v-model="scope.row[item.prop_h]" placeholder="00" @blur="checkHour(scope.row[item.prop_h])"></el-input>:
            <el-input class="inpBox" v-model="scope.row[item.prop_m]" placeholder="00" @blur="checkMinute(scope.row[item.prop_m])"></el-input>:
            <el-input class="inpBox" v-model="scope.row[item.prop_s]" placeholder="00" @blur="checkSecond(scope.row[item.prop_s])"></el-input>
          </div>
          <!-- 其他 -->
          <span v-else>{{
            (
              item.format ||
              function (val) {
                return val;
              }
            )(scope.row[item.prop])
          }}</span>

          <!-- 插槽 -->
          <slot
            :name="item.prop"
            v-bind="{
              row: scope.row,
              $index: scope.$index,
              item,
            }"
          ></slot>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div v-if="isPage">
      <section class="ces_pagination" v-if="rows && rows != null && rows.length != 0">
        <el-pagination
          style="display: flex; justify-content: center; flex-grow: 1"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          layout="total,sizes ,prev, pager, next,jumper"
          :page-size="pagination.rowsPerPage"
          :current-page="pagination.page"
          :total="pagination.total"
          :page-sizes="pageSizes"
        ></el-pagination>
      </section>
    </div>
  </div>
</template>

<script>
import helper from "@/services/helper";

import Vue from "vue";
export default {
  components: {},
  props: {
    // tableHeight:{ type: Number, default: 580 },
    // 表格型号：mini,medium,small
    size: { type: String, default: "medium" },
    isBorder: { type: Boolean, default: false },
    // 表格数据
    headers: { type: Array, default: () => [] },
    // 分页数据
    pagination: {
      type: Object,
      default: () => ({ rowsPerPage: 10, page: 1, total: 0 }),
    },
    pageSizes: { type: Array, default: () => [10, 25, 50] },
    rows: { type: Array, default: () => [] },
    selection: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Boolean,
      default: false,
    },
    isPage: {
      type: Boolean,
      default: false,
    },
    rowKey: {
      type: String,
      default: "id",
    },
    hasScroll: {
      type: Boolean,
      default: true,
    },
    isHeightLight: { type: Boolean, default: false },
  },
  data() {
    return {
      helper: helper,
      customRow: [],
      customHeaders: [
        {
          label: "序号",
          type: "indexmove",
          align: "center",
          width: 117,
          handle: (val, index) => {
            if (val == 1) {
              let temp = this.customRow[index - 1];
              Vue.set(this.customRow, index - 1, this.customRow[index]);
              Vue.set(this.customRow, index, temp);
            } else if (val == 2) {
              let i = this.customRow[index + 1];
              Vue.set(this.customRow, index + 1, this.customRow[index]);
              Vue.set(this.customRow, index, i);
            }
          },
        },
        {
          label: "内容",
          prop: "label",
          align: "center",
        },
        {
          label: "宽度",
          prop: "width",
          type: "input",
          align: "center",
        },
        {
          label: "是否展示",
          prop: "switch",
          type: "switch",
          align: "center",
        },
      ],
      radio: "",
      tableHeight: 0,
    };
  },
  computed: {
    getheaders() {
      var header = this.headers;
      return header;
    },
    btnSwitch: function () {
      return this.getheaders.filter(function (item) {
        return item.switch;
      });
    },
  },
  watch: {
    rows: {
      handler(val) {
        this.toggleSelection(val);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.setTPMaxHeight()
    window.addEventListener("resize", ()=>{
      this.setTPMaxHeight()
    });
  },
  methods: {
    checkHour(val) {
      if ( isNaN(val) ) {
        this.$message.error("请输入数字")
      } else {
        if (val == undefined || val < 0 || val > 99 ) {
          this.$message.error("请输入0到99之间的小时数")
        }
      }
    },
    checkMinute(val) {
      if ( isNaN(val) ) {
        this.$message.error("请输入数字")
      } else {
        if (val < 0 || val > 59) {
          this.$message.error("请输入0到60之间的分钟数")
        } 
      }
    },
    checkSecond(val) {
      if ( isNaN(val) ) {
        this.$message.error("请输入数字")
      } else {
        if (val < 0 || val > 59) {
          this.$message.error("请输入0到60之间的秒数")
        } 
      }
    },
    setTPMaxHeight(val) {
      this.$nextTick(() => {
        let height = this.isPage
          ? this.$refs.dd_tp.clientHeight - 64
          : this.$refs.dd_tp.clientHeight;
           console.log(height)
        // console.log(this.$refs.dd_tp.clientHeight);
        if (typeof height == "number") {
          this.tableHeight = height;
          // console.log(height)
        } else {
          this.tableHeight = "";
        }
      });
    },
    toggleSelection(rows) {
      let self = this;
      rows.forEach((row) => {
        if (row.sel == 1) {
          console.log(row);
          self.$nextTick(function () {
            self.$refs.cesTable.toggleRowSelection(row, true);
          });
        }
      });
    },
    indexMethod(index) {
      index =
        index + 1 + (this.pagination.page - 1) * this.pagination.rowsPerPage;
      return index;
    },
    handleCurrentChange(val) {
      this.pagination.page = val;
      this.$emit("sizechange", this.pagination);
    },
    handleSizeChange(val) {
      this.pagination.rowsPerPage = val;
      this.pagination.page = 1; // fixed by xuyiming：切换pagesize后应该将页码重置为1，不然当页数据可能为空
      this.$emit("sizechange", this.pagination);
    },
    sortChange(column) {
      this.$emit("sortChange", column);
    },

    handleMultipleStart() {
      this.$refs.customColumns.show();
      // console.log(this.getheaders);
      this.customRow = JSON.parse(JSON.stringify(this.getheaders));
    },
    close_add() {
      this.$refs.customColumns.hide();
    },
    getSlotName(column) {
      if (typeof column.render === "string") {
        return column.render;
      }
      return `${column.prop}-column`;
    },
    handleoperate(row, label, option = "") {
      var obj = {
        row,
        label,
        option,
      };
      this.$emit("handleoperate", obj);
    },
    handlehref(row) {
      this.$emit("handlehref", row);
    },
    confirm_btn() {
      this.$refs.customColumns.hide();
      this.$emit("refresh", this.customRow);
    },
    //全选
    selectAll(rows) {
      if (rows && rows.length) {
        this.$refs.cesTable.setCurrentRow(rows);
      } else {
        this.$nextTick(() => {
          this.$refs.cesTable && this.$refs.cesTable.clearSelection();
        });
      }
      this.$emit("selectnum", rows);
    },
    select(select, rows) {
      this.$emit("selectnum", select);
    },
    // 单选
    getCurrentRow(val) {
      this.$emit("radionum", val);
    },
    // 点击整行
    clickTable(row) {
      this.$emit("clickRow", row);
    },
    // cleanSel() {
    //   this.$nextTick(() => {
    //     this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
    //   });
    //   this.$emit("selectnum", []);
    // }
    isDisabled(row, index) {
      // 是否不可选
      // if (row.type == "") {
      //   return false;
      // } else {
      return true;
      // }
    },
    tableRowClassName({ row, rowIndex }) {
      return this.isHeightLight && row.orderId === +this.$route.query.orderId
        ? "success-row"
        : "";
    },
    inputValidate(val) {
      this.$emit("inputValidate", val);
    },
  },
};
</script>
<style>
.el-table .success-row {
  background: #e1e1e6;
}

.label-data {
  background: #fff;
  padding: 0;
  height: calc(100%);
  display: flex;
  flex-direction: column;
}
.is-page {
  height: 818px;
  position: relative;
}
.el-table::before {
  height: 0 !important;
}
.el-table th.gutter {
  display: table-cell !important;
}
.el-table colgroup.gutter {
  display: table-cell !important;
}

.el-table thead tr th {
  background-color: #f8f9fd;
}
.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
.el-table tbody tr:hover > td {
  background-color: #e4ecf8 !important;
}
.theme--light.el-table tbody tr:not(:last-child) {
  border-bottom: none;
}
.el-table th > .cell {
  color: #1a2236;
}
.el-table th > .cell,
.el-table td > .cell {
  /* text-align: center; */
  font-size: 14px;
}
.el-table tr {
  color: #58617a;
}
.el-table__empty-text {
  color: #58617a;
  font-size: 14px;
}
.el-input__inner {
  border: 1px solid #bcc1d0;
  /* border-radius: 17px; */
  color: #58617a;
  background: #fff;
}
em {
  font-style: normal;
}
.el-input.is-disabled .el-input__inner {
  background-color: #e3e5ef;
  border-color: #bcc1d0;
  color: #242c43;
}
.el-table--mini td,
.el-table--mini th {
  padding: 0;
}
.el-table--mini td .cell,
.el-table--mini th .cell {
  padding: 11px 0 !important;
  width: 100% !important;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  background: #fff;
}
.ces_pagination {
  margin-top: 20px;
  padding-bottom: 12px;
  /* padding-bottom: 20px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%); */
}
.chName.is-dark {
  background: #5c82ff !important;
}
.chName[x-placement^="top"] .popper__arrow,
.chName[x-placement^="top"] .popper__arrow::after {
  border-top-color: #5c82ff !important;
}
.disfail {
  color: #58617a !important;
}
.disture {
  color: #bcc1d0 !important;
}
.moreList .el-dropdown-link {
  color: #264dd9;
  outline: none;
}
.moreList .el-dropdown-link .el-icon-arrow-down {
  display: none;
}
.input-style {
  display: flex;
  align-items: center;
  padding: 0 24px 0 8px;
  box-sizing: border-box;
}
.input-style span,
.table-label {
  width: 100px;
  font-weight: bold;
  color: #242c43;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-form-item {
  margin-bottom: 0px;
  padding: 3px 0;
}
.el-form-item__content {
  line-height: 0;
}
.el-form-item__error {
  padding-top: 0;
}
.dark-image {
  width: 16px;
  height: 16px;
  /* background: url("../../assets/image/list_btn_setting.png") no-repeat center
    center; */
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  cursor: pointer;
}
.primary-del,
.top,
.relation {
  font-family: MicrosoftYaHei-Bold;
  font-size: 14px;
  color: #0c77d4;
  cursor: pointer;
  margin: 0 3px;
}
.hrefclass {
  color: #264dd9;
  cursor: pointer;
}
.rework {
  background-color: #fffee8;
  border-radius: 4px;
  border: solid 1px #e4c884;
  padding: 2px 3px;
  color: #ef9433;
  font-size: 12px;
}
.icp-img {
  margin-right: 5px;
}
.custom-btn {
  width: 36px;
  padding: 2px 6px;
  font-size: 12px;
  background-color: #fceeef;
  border-radius: 4px;
  border: solid 1px #fca2ab;
  color: #e15260;
}
.ordinary {
  color: #8890a7;
  width: 36px;
  padding: 2px 6px;
  font-size: 12px;
  border-radius: 4px;
  background-color: #f3f4f8;
  border: solid 1px #bcc1d0;
}
.inpBox {
  width: 27%;
}
.inpBox .el-input__inner {
  padding: 0;
  text-align: center;
}
/* .el-table{
 height: 100%!important;
} */
/* el-table 滚动条样式 */
.el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {
    width: 10px;
}
 
.el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
}
 
.el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
}
 
.el-table__header-wrapper .has-gutter th:nth-last-child(2) {
    border-right: 0;
}
/* el-table 滚动条样式结束 */
.dd_tp{
  height: calc(100vh - 250px);
  /* border: 1px solid red; */
}
</style>