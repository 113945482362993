<template>
    <div>
        <div v-if="type == 'cropper'">
            <cropper
                    :max="max"
                    :readOnly="readOnly"
                    :fileArray="fileArray"
                    :fit="fit"
                    @getNewUrl="getNewUrl"
            ></cropper>
        </div>
        <div v-else>
            <el-upload
                    class="eeupload"
                    action="/bgfile/upload"
                    :file-list="fileArray"
                    :list-type="type == 'video' ? 'picture-card' : ''"
                    :limit="max"
                    :multiple="multiple"
                    :on-remove="handleRemove"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                    :class="{ hide: hideUpload || readOnly }"
                    :readOnly="readOnly"
                    :data="anotherData"
                    :drag="drag"
                    :disabled="disabled"
                    :accept="accepts"
            >
                <el-button size="small" type="primary"
                >上传视频文件</el-button>
                <div
                        slot="tip"
                        class="el-upload__tip"
                        style="min-width: 500px;min-height: 20px"
                >
                    支持文件格式：.mp4、.mov、.avi，单个文件不能超过50M。
                </div>
                <!--        <i class="el-icon-plus"></i>-->
            </el-upload>
        </div>
    </div>

</template>

<script>
    export default {
        data(){
            return {
                hideUpload: false,
                fileArray: [],
                anotherData: {
                    fileType: this.type,
                },
            };
        },
        props: {
            multiple: {
                type: Boolean,
                default: false,
            },
            directory: {
                type: String,
                default: "",
            },
            max: {
                type: Number,
                default: 1,
            },
            list: {
                type: Array,
                default: () => [],
            },
            type: {
                type: String,
                default: "",
            },
            fileType: {
                type: String,
                default: "",
            },
            readOnly: {
                type: Boolean,
                default: false,
            },
            fit: {
                type: String,
                default: "",
            },
            drag: {
                type: Boolean,
                default: false,
            },
            unique: {
                type: Boolean,
                default: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            up_fz: {
                type: String,
                default: "",
            },
            accepts: {
                type: String,
                default: "",
            },
            imgSize:{
                type: Boolean,
                default: false
            }
        },
        watch: {
            list(value) {
                this.getFileArray(value);
            },
        },
        created() {
            if (!this.unique) {
                this.anotherData.uniqueCode = this.unique;
            }
        },
        mounted() {
            this.getFileArray(this.list);
        },
        methods: {

            getFileArray(value) {
                var getListImg = [];
                if (value && value.length != 0) {
                    for (var i = 0; i < value.length; i++) {
                        getListImg.push({
                            name: value[i],
                            url: value[i],
                        });
                    }
                }
                this.fileArray = [...getListImg];
                this.hideUpload = this.fileArray.length >= this.max;
            },
            beforeAvatarUpload(file) {
                console.log(file.type);
                if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb', 'video/mov'].indexOf(file.type) == -1) {
                    this.$message.error("请上传正确的视频格式");
                    return false;
                }
                const isVideo = true;
                const isLt50M =  file.size / 1024 / 1024 < 50;
                if (!isLt50M) {
                    this.$message.error("上传 视频 文件大小不能超过 50MB!");
                }
                return isVideo && isLt50M;
            },
            handleAvatarSuccess(response, file, fileList) {
                console.log(2);
                if (response.code == 0) {
                    this.fileArray.push({
                        url: response.data.visitURL,
                        name: file.name,
                    });
                }
                this.$emit("getNewList", this.fileArray);
                this.hideUpload = fileList.length >= this.max;
            },
            handleRemove(file, fileList) {
                console.log(3);
                // if (!(fileList && fileList.length > 0)) {
                //   return;
                // }
                let url = file.url;
                let detection = true;
                if (url) detection = url.indexOf("blob") != -1;
                if (detection) {
                    this.fileArray.forEach((item, i, arr) => {
                        if (item.url === file.url) {
                            this.fileArray.splice(i, 1);
                        }
                    });
                } else {
                    this.fileArray.forEach((item, i, arr) => {
                        if (item.url === file.url) {
                            this.fileArray.splice(i, 1);
                        }
                    });
                }

                this.hideUpload = fileList.length >= this.max;
                this.$emit("getNewList", this.fileArray);
            },
            remove_pic() {
                document
                    .querySelector(".eeupload")
                    .querySelector(".el-upload-list__item-delete")
                    .click();
                setTimeout(() => {
                    document.querySelector(".eeupload").querySelector(".el-upload").click();
                }, 1000);
            },
            getNewUrl(val) {
                console.log(val);
                let fileObj = [{ name: val, url: val }];
                this.$emit("getNewList", fileObj);
            },
        },
        name: "upload_video"
    }
</script>

<style scoped>
    .uploads {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .upload-demo {
        flex: 1 1 auto;
        min-width: 360px;
        padding-right: 20px;
    }
    .upload_alert {
        /* flex: 0 0 auto; */
        /* width: 260px;
        height: 115px; */
        background-color: #f8f9fd;
        border-radius: 4px;
        padding: 20px;
        color: #8890a7;
        font-size: 14px;
        line-height: 26px;
        display: flex;
        align-items: flex-start;
    }
    .up_alert_i {
        margin-right: 8px;
        line-height: 26px;
    }
    .el-upload-dragger .el-upload__text {
        color: #8890a7;
    }
    .el-upload-dragger .el-upload__text em {
        color: #264dd9;
    }
    .el-upload-dragger .el-icon-upload {
        margin: 35px 0 16px;
        line-height: 40px;
    }
</style>
<style>
    .hide .el-upload--picture-card {
        display: none;
    }
    .up_fz {
        color: #a9aec0;
    }
</style>
