<template>
  <div>
    <div>
      <filterSearch
        ref="filter"
        class="gas_order_filter"
        :data="filterData"
        @serach="search"
      />
    </div>
    <vueTable
      ref="table"
      :headers="headers"
      :rows="datas"
      :selection="false"
      :pagination="pagination"
      :isPage="true"
      :index="true"
      hasScroll
      class="table"
      @sizechange="sizechange"
    >
    <template v-slot:operations="{ row }">
         <el-button type="text" class="primary-del" @click="detail(row)">
          详情
        </el-button>
    </template>
    </vueTable>
  </div>
</template>
<script>
//import引入的组件
// import VueTable from "../../../components/table/table.vue";
// import tableFilter from "../../../components/table/table_filter.vue";
import { getBSextListApi } from "../../api/special";
export default {
  components: {
    vueTable: () => import("../../components/table/table.vue"),
    filterSearch: () => import("../../components/table/table_filter.vue"),
  },
  data() {
    return {
      filterData: [
        {
          label: "用户名",
          type: "input",
          key: "nickName",
          placeholder: "请输入用户名",
        },
        {
          label: "用户手机号",
          type: "input",
          key: "phoneNum",
          placeholder: "请输入用户手机号",
        },
      ],
      searchData: {
        nickName: "",
        phoneNum: "",
      },
      headers: [
        {
          prop: "expertName",
          label: "用户名",
          width: "",
        },
        {
          prop: "phoneNum",
          label: "用户手机号",
          width: "",
        },
        {
          prop: "bannedStatus",
          label: "帐号状态",
          width: "",
        },
        {
          prop: "registerDate",
          label: "注册时间",
          width: "",
        },
        {
          prop: "online",
          label: "在线状态",
          width: "",
        },
        {
          prop: "updateDate",
          label: "活跃时间",
          width: "",
        },
        {
          label: "操作",
          width: 220,
          type: "operations",
        },
      ],
      datas: [{}],
      pagination: {
        page: 1,
        rowsPerPage: 10,
        total: 0,
      },
    };
  },
  //方法集合
  methods: {
    search(value) {
      this.searchData.nickName = value.nickName;
      this.searchData.phoneNum = value.phoneNum;
      (this.pagination.rowsPerPage = 10), (this.pagination.page = 1);
      this.getBSextListApi();
    },
    //获取用户信息
    async getBSextListApi() {
      let res = await getBSextListApi(
        this.pagination.rowsPerPage,
        this.pagination.page,
        this.searchData
      );
      if (res.data.code == "000") {
        res.data.results.forEach((item) => {
          item.bannedStatus == 0
            ? (item.bannedStatus = "正常")
            : item.bannedStatus == 1
            ? (item.bannedStatus = "警告")
            : item.bannedStatus == 2
            ? (item.bannedStatus = "封禁")
            : (item.bannedStatus = "---");
          item.online == 0
            ? (item.online = "在线")
            : item.online == 1
            ? (item.online = "离线")
            : item.online == 2
            ? (item.online = "登出")
            : (item.online = "---");
        });
        this.datas = res.data.results;
        this.pagination.total = res.data.totalCount;
      }
    },
    sizechange(obj) {
      this.pagination.rowsPerPage = obj.rowsPerPage;
      this.getBSextListApi();
    },
  },
  //生命周期 - 挂载前
  created() {
    this.getBSextListApi();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang='scss' scoped>
</style>

