<template>
  <div>
    <el-input placeholder="输入关键字进行过滤" v-model="filterText"></el-input>
    <el-tree
      class="tree_style"
      :data="data"
      :props="defaultProps"
      default-expand-all
      show-checkbox
      node-key="id"
      :check-strictly="true"
      :filter-node-method="filterNode"
      :disabled="nodeeCheckDisabled"
      @check="handleCheckChange"
      ref="tree"
    ></el-tree>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => {
        [];
      },
    },
    need_value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    filterText: "",
    defaultProps: {
      children: "children",
      label: "label",
    },
  }),
  created() {},
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    nodeeCheckDisabled(data, node) {
      console.log(data, node);
    },
    handleCheckChange(data, tree) {
      this.$refs.tree.setCheckedNodes([data]);
      console.log(data, tree);
      if (this.need_value) {
        this.$emit("getValue", [data]);
      }
    },
  },
};
</script>

<style>
.tree_style .el-tree-node__content {
  height: 30px;
}
/* .tree_style .el-tree-node.is-current > .el-tree-node__content {
  color: #515fe7;
  background-color: #e6ebfe;
} */
.el-tree {
  padding-right: 6px;
}
.tree_style .el-tree-node .is-leaf + .el-checkbox .el-checkbox__inner {
  display: inline-block;
}
.tree_style .el-tree-node .el-checkbox .el-checkbox__inner {
  display: none;
}
.tree_style .is-leaf.el-tree-node__expand-icon.el-icon-caret-right {
  width: 0;
  padding: 4px;
}
.tree_style .el-checkbox__inner {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #ccc;
  border-color: #ccc;
}
.tree_style .is-checked .el-checkbox__inner {
  background-color: #ccc;
  border-color: #ccc;
}
.tree_style .el-checkbox__inner::after {
  width: 10px;
  height: 10px;
  border: 0;
  background-color: #515fe7;
  border-radius: 50%;
  left: 2px;
  top: 2px;
}
</style>