<template>
  <div>
    <div>
      <filterSearch
        ref="filter"
        class="gas_order_filter"
        :data="filterData"
        @serach="search"
      />
    </div>
    <vueTable
      ref="table"
      :headers="headers"
      :rows="datas"
      :selection="false"
      :pagination="pagination"
      :isPage="true"
      :index="true"
      hasScroll
      class="table"
      @sizechange="sizechange"
    >
      <template v-slot:operations="{ row }">
        <el-button type="text" class="primary-del" @click="detail(row)">
          详情
        </el-button>
      </template>
    </vueTable>
    <sysDialog
      :title="Dialog.title"
      :visible="Dialog.visible"
      :width="Dialog.width"
      :height="Dialog.height"
      :isfoot="Dialog.isfoot"
      @onClose="onClose"
    >
      <div slot="content">
        <div class="container">
          <el-row>
            <el-col :span="24"
              ><div>
                <el-image :src="detailInfo.headimage"></el-image></div
            ></el-col>
          </el-row>
          <el-row>
            <el-col :span="6"
              ><div>专家昵称：{{ detailInfo.expertName }}</div></el-col
            >
            <el-col :span="6"
              ><div>专家手机号：{{ detailInfo.phoneNum }}</div></el-col
            >
            <el-col :span="6"
              ><div>接单次数：{{ detailInfo.ordertimes }}</div></el-col
            >
            <el-col :span="6"
              ><div>专家价格:{{ detailInfo.price }}</div></el-col
            >
          </el-row>
          <el-row>
            <el-col :span="24"
              ><div>详细介绍：{{ detailInfo.introduce }}</div></el-col
            >
          </el-row>
          <el-row>
            <el-col :span="24"
              ><div>简介：{{ detailInfo.intro }}</div></el-col
            >
          </el-row>
          <el-row>
            <el-col :span="24"
              ><div>给用户的话：{{ detailInfo.giveUser }}</div></el-col
            >
          </el-row>
          <el-row>
                 <!-- <el-col :span="6"
              ><div>金币：{{ detailInfo.gold }}</div></el-col
            > -->
             <el-col :span="6"
              ><div>分成：{{ detailInfo.divided }}</div></el-col
            >
            <el-col :span="6"
              ><div>是否被封禁：{{ detailInfo.bannedStatus }}</div></el-col
            >
            <el-col :span="12"
              ><div>封禁原因:{{ detailInfo.bannedResult }}</div></el-col
            >
          </el-row>
          <el-row>
            <el-col :span="6"
              ><div>活跃时间：{{ detailInfo.updateDate }}</div></el-col
            >
            <el-col :span="6"
              ><div>申请时间：{{ detailInfo.registerDate }}</div></el-col
            >
            <el-col :span="6"
              ><div>星级,评价：{{ detailInfo.star }}</div></el-col
            >
            <el-col :span="6"
              ><div>在线状态：{{ detailInfo.online }}</div></el-col
            >
          </el-row>
          <el-row>
            <el-col :span="6"
              ><div>是否显示：{{ detailInfo.isShow }}</div></el-col
            >
            <el-col :span="6"
              ><div>总售出时间：{{ detailInfo.totalTime }}</div></el-col
            >
            <el-col :span="6"
              ><div>是否加入工作室：{{ detailInfo.isRoom }}</div></el-col
            >
            <el-col :span="6"
              ><div>加入工作室时间：{{ detailInfo.joinTime }}</div></el-col
            >
          </el-row>
        </div>
        <div class="foot_box">
          <el-button type="warning" size="mini" @click="warnBtn"
            >修改</el-button
          >
        </div>
      </div>
    </sysDialog>
    <sysDialog
      :title="editDialog.title"
      :visible="editDialog.visible"
      :width="editDialog.width"
      :height="editDialog.height"
      :isfoot="editDialog.isfoot"
      :top="20"
      @onClose="editClose"
      @onConfirm="onConfirm"
    >
     <div slot="content" >
        <div style="width:100%">
          <DefaultForm
          ref="addForm"
          slot="content"
          :data="formData"
          :defaultData="defaultData"
          labelPosition="left"
          >
          </DefaultForm>
          </div>
      </div>
    </sysDialog>
  </div>
</template>
<script>
//import引入的组件
import vueTable from "../../../components/table/table.vue";
import filterSearch from "../../../components/table/table_filter.vue";
import sysDialog from "../../../components/SysDialog.vue";
import DefaultForm from "../../../components/form/default_form.vue";
import {Message} from "element-ui"
import { getBSextListApi ,updateWdExpert} from "../../../api/special";
export default {
  components: {
    vueTable,
    sysDialog,
    filterSearch,
    DefaultForm
  },
  data() {
    return {
     defaultData: {
     },
         rules: {
          ordertimes: [
            { required: true, message: "请输入接单次数", trigger: "change" },
        ],
           star: [
            { required: true, message: "请输入星级,评价", trigger: "change" },
        ],
           divided: [
            { required: true, message: "请输入分成", trigger: "change" },
        ],
            totalTime: [
            { required: true, message: "请输入总出售时间", trigger: "change" },
        ],
        gold: [
            { required: true, message: "请输入金币", trigger: "change" },
        ],
      },
      formData: [
    {
        label: "接单次数",
        type: "input",
        placeholder: "请输入接单次数",
        key: "ordertimes",
        width: "100%",
        disabled: false,
    }, 
    {
        label: "星级,评价",
        type: "input",
        placeholder: "请输入星级,评价",
        key: "star",
        width: "100%",
        disabled: false,
    },
        {
        label: "分成",
        type: "input",
        placeholder: "请输入分成",
        key: "divided",
        width: "100%",
        disabled: false,
    }, {
        label: "金币",
        type: "input",
        placeholder: "请输入金币",
        key: "gold",
        width: "100%",
        disabled: false,
    },
     {
         label: "总出售时间",
        type: "input",
        placeholder: "请输入总出售时间",
        key: "totalTime",
        width: "100%",
        disabled: false,
        }
      ],
      Dialog: {
        title: "咨询师详情",
        visible: false,
        width: 1100,
        isfoot: false,
      },
      editDialog: {
        title: "修改",
        visible: false,
        width: 400,
        isfoot: true,
        top: 10,
      },
      detailInfo: {},
      filterData: [
        {
          label: "用户名",
          type: "input",
          key: "nickName",
          placeholder: "请输入用户名",
        },
        {
          label: "用户手机号",
          type: "input",
          key: "phoneNum",
          placeholder: "请输入用户手机号",
        },
      ],
      searchData: {
        nickName: "",
        phoneNum: "",
      },
      headers: [
        {
          prop: "expertName",
          label: "用户名",
          width: "",
        },
        {
          prop: "phoneNum",
          label: "用户手机号",
          width: "",
        },
        {
          prop: "bannedStatus",
          label: "帐号状态",
          width: "",
        },
        {
          prop: "registerDate",
          label: "注册时间",
          width: "",
        },
        {
          prop: "online",
          label: "在线状态",
          width: "",
        },
        {
          prop: "updateDate",
          label: "活跃时间",
          width: "",
        },
        {
          label: "操作",
          width: 220,
          type: "operations",
        },
      ],
      datas: [{}],
      pagination: {
        page: 1,
        rowsPerPage: 10,
        total: 0,
      },
    };
  },
  //方法集合
  methods: {
    search(value) {
      this.searchData.nickName = value.nickName;
      this.searchData.phoneNum = value.phoneNum;
      (this.pagination.rowsPerPage = 10), (this.pagination.page = 1);
      this.getBSextListApi();
    },
    //获取用户信息
    async getBSextListApi() {
      let res = await getBSextListApi(
        this.pagination.rowsPerPage,
        this.pagination.page,
        this.searchData
      );
      if (res.data.code == "000") {
        res.data.results.forEach((item) => {
          item.bannedStatus == 0
            ? (item.bannedStatus = "正常")
            : item.bannedStatus == 1
            ? (item.bannedStatus = "警告")
            : item.bannedStatus == 2
            ? (item.bannedStatus = "封禁")
            : (item.bannedStatus = "---");
          item.online == 0
            ? (item.online = "在线")
            : item.online == 1
            ? (item.online = "离线")
            : item.online == 2
            ? (item.online = "登出")
            : (item.online = "---");
          item.isShow == 0
            ? (item.isShow = "不显示")
            : item.isShow == 1
            ? (item.isShow = "显示")
            : (item.isShow = "---");
          item.isRoom == 0
            ? (item.isRoom = "否")
            : item.isRoom == 1
            ? (item.isRoom = "是")
            : (item.isRoom = "---");
        });
        this.datas = res.data.results;
        this.pagination.total = res.data.totalCount;
      }
    },
    warnBtn() {
      this.editDialog.visible = true;
      this.defaultData=this.detailInfo
    },
    editClose() {
      this.editDialog.visible = false;
    },
    sizechange(obj) {
      this.pagination.rowsPerPage = obj.rowsPerPage;
      this.getBSextListApi();
    },
    detail(row) {
      this.detailInfo = row;
      this.Dialog.visible = true;
    },
    onClose() {
      this.Dialog.visible = false;
    },
    onConfirm(){
     this.$refs.addForm.$refs.formInline.validate(async(valid) => {
          if(valid){
            let obj={
              id:this.detailInfo.id,
              ordertimes:Number(this.$refs.addForm.formInline.ordertimes),
              divided:Number(this.$refs.addForm.formInline.divided),
              star:Number(this.$refs.addForm.formInline.star),
              gold:Number(this.$refs.addForm.formInline.gold),
              totalTime:Number(this.$refs.addForm.formInline.totalTime)
            }
            console.log(JSON.stringify(obj))
           let res= await updateWdExpert(obj)
           if(res.data.code=="000"){
            Message({
              type: "success",
              message: "修改成功",
            });
            this.getBSextListApi()
            this.editDialog.visible = false;
            this.Dialog.visible = false;
            this.defaultData={}
           }
          }
     })
    }
  },
  //生命周期 - 挂载前
  created() {
    this.getBSextListApi();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang='scss' scoped>
.container {
  width: 100%;
  height: 100%;
}
.el-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.el-row {
  margin-bottom: 30px;
  font-size: 16px;
}
.foot_box {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
::v-deep .el-form--inline .el-form-item__label{
  width: 100px!important;
}
</style>

