import http from '../http/request'
export async function getBScouponListApi(pageSize,currentPage,parm) {
    return await http.post(`/backStage/getBScouponList/${pageSize}/${currentPage}`, parm)
}
export async function getBSVouListApi(pageSize,currentPage,parm) {
    return await http.post(`/backStage/getBSVouList/${pageSize}/${currentPage}`, parm)
}
export async function insertCoupon(parm) {
    return await http.get(`/backStage/insertCoupon`, parm)
}
// /backStage/getBSVouList/{pageSize}/{currentPage}