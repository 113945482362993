<template>
  <div>
    <div>
      <filterSearch
        ref="filter"
        class="gas_order_filter"
        :data="filterData"
        @serach="search"
      />
    </div>
    <vueTable
      ref="table"
      :headers="headers"
      :rows="datas"
      :selection="false"
      :pagination="pagination"
      :isPage="true"
      :index="true"
      hasScroll
      class="table"
      @sizechange="sizechange"
    >
      <template v-slot:operations="{ row }">
        <el-button type="primary" size="mini" @click="backOrder(row)">
          退单
        </el-button>
        <el-button type="warning" size="mini" @click="finshOrder(row)">
          完成
        </el-button>
      </template>
    </vueTable>
  </div>
</template>
<script>
//import引入的组件
// import VueTable from "../../../components/table/table.vue";
// import tableFilter from "../../../components/table/table_filter.vue";
import { Message } from "element-ui";
import {
  getOrderListApi,
  backOrderById,
  finshOrderById,
} from "../../../api/order";
export default {
  components: {
    vueTable: () => import("../../../components/table/table.vue"),
    filterSearch: () => import("../../../components/table/table_filter.vue"),
  },
  data() {
    return {
      filterData: [
      {
          label: "订单ID",
          type: "input",
          key: "id",
          placeholder: "请输入订单ID",
        },
        {
          label: "用户名",
          type: "input",
          key: "userName",
          placeholder: "请输入用户名",
        },
        {
          label: "专家姓名",
          type: "input",
          key: "advisoryName",
          placeholder: "请输入专家姓名",
        },
          {
          label: "支付状态",
          type: "select",
          key: "payStatus",
          placeholder: "请选择支付状态",
          arr:[{
            value:"",
            label:"全部"
          },
          {
            value:0,
            label:"待支付"
          },{
            value:1,
            label:"支付成功"
          },{
            value:2,
            label:"支付失败"
          },{
            value:3,
            label:"已退款"
          }]
        },
      {
          label: "订单状态",
          type: "select",
          key: "chatStatus",
          placeholder: "请选择订单状态",
          arr:[{
            value:"",
            label:"全部"
          },
          {
            value:0,
            label:"待支付"
          },{
            value:1,
            label:"待咨询"
          },{
            value:2,
            label:"咨询中"
          },{
            value:3,
            label:"已完成"
          }]
        },
      ],
      searchData: {
        // userName: "",
        // userPhone: "",
        // advisoryName: "",
        // advisoryPhone: "",
      },
      headers: [
        {
          prop: "id",
          label: "订单ID",
          width: "",
        },
        {
          prop: "payStatus",
          label: "支付状态",
          width: "",
        },
        {
          prop: "price",
          label: "支付价格",
          width: "",
        },
        {
          prop: "userName",
          label: "用户姓名",
          width: "",
        },
        {
          prop: "userPhone",
          label: "用户手机号",
          width: "",
        },
        {
          prop: "advisoryPhone",
          label: "专家手机号",
          width: "",
        },
        {
          prop: "advisoryName",
          label: "专家姓名",
          width: "",
        },
        {
          prop: "chatStatus",
          label: "订单状态",
          width: "",
        },
        {
          prop: "orderTime",
          label: "下单时间",
          width: "",
        },
        {
          prop: "orderFrom",
          label: "订单来源",
          width: "",
        },
        {
          prop: "tranStatus",
          label: "交易类型",
          width: "",
        },
        {
          label: "操作",
          width: 220,
          type: "operations",
        },
      ],
      datas: [{}],
      pagination: {
        page: 1,
        rowsPerPage: 10,
        total: 0,
      },
    };
  },
  //方法集合
  methods: {
    search(value) {
      this.searchData.id = value.id;
      this.searchData.userName = value.userName;
      this.searchData.advisoryName = value.advisoryName;
      this.searchData.chatStatus = value.chatStatus;
        this.searchData.payStatus = value.payStatus;
      (this.pagination.rowsPerPage = 10), (this.pagination.page = 1);
      this.getOrderListApi();
    },
    //获取用户信息
    async getOrderListApi() {
      let res = await getOrderListApi(
        this.pagination.rowsPerPage,
        this.pagination.page,
        this.searchData
      );
      if (res.data.code == "000") {
        res.data.results.forEach((item) => {
          item.payStatus == 0
            ? (item.payStatus = "待支付")
            : item.payStatus == 1
            ? (item.payStatus = "支付成功")
            : item.payStatus == 3
            ? (item.payStatus = "已退款")
            : (item.payStatus = "---");
          item.chatStatus == 0
            ? (item.chatStatus = "待支付")
            : item.chatStatus == 1
            ? (item.chatStatus = "待咨询")
            : item.chatStatus == 2
            ? (item.chatStatus = "咨询中")
            : item.chatStatus == 3
            ? (item.chatStatus = "已完成")
            : (item.chatStatus = "---");
          item.orderFrom == 1 || item.orderFrom == 5
            ? (item.orderFrom = "语音订单")
            : item.orderFrom == 2
            ? (item.orderFrom = "课程订单")
            : item.orderFrom == 3
            ? (item.orderFrom = " 用户充值")
            : item.orderFrom == 4
            ? (item.orderFrom = "测试订单")
            : item.orderFrom == 9 || item.orderFrom == 10
            ? (item.orderFrom = "文字订单")
            : (item.orderFrom = "---");
          item.tranStatus == 1
            ? (item.tranStatus = "余额")
            : item.tranStatus == 2
            ? (item.tranStatus = "微信")
            : item.tranStatus == 3
            ? (item.tranStatus = "支付宝")
            : (item.tranStatus = "---");
        });
        //
        this.datas = res.data.results;
        this.pagination.total = res.data.totalCount;
      }
    },
    sizechange(obj) {
      this.pagination.rowsPerPage = obj.rowsPerPage;
      this.getOrderListApi();
    },
    backOrder(row) {
      this.$confirm("退单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await backOrderById({ id: row.id });
          if (res.data.code == "000") {
            Message({
              type: "success",
              message: "退单成功!",
            });
          }
        })
        .catch(() => {
          Message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    finshOrder(row) {
              this.$confirm("完成, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await finshOrderById({ id: row.id });
          if (res.data.code == "000") {
            Message({
              type: "success",
              message: "完成成功!",
            });
          }
        })
        .catch(() => {
          Message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
  },
  //生命周期 - 挂载前
  created() {
    this.getOrderListApi();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang='scss' scoped>
</style>

