<template>
  <div>
    <div v-if="type == 'cropper'">
      <cropper
        :max="max"
        :readOnly="readOnly"
        :fileArray="fileArray"
        :fit="fit"
        @getNewUrl="getNewUrl"
      ></cropper>
    </div>
    <div v-else>
      <el-upload
        class="eeupload"
        action="/bgfile/upload"
        :file-list="fileArray"
        :list-type="type == 'image' ? 'picture-card' : ''"
        :limit="max"
        :multiple="multiple"
        :on-remove="handleRemove"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload"
        :class="{ hide: hideUpload || readOnly }"
        :readOnly="readOnly"
        :data="anotherData"
        :drag="drag"
        :disabled="disabled"
        :accept="accepts"
      >
        <div v-if="drag">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            <span class="up_fz">将文件拖到此处，或</span>
            <em>点击上传</em><br />
            <span class="up_fz">{{ up_fz }}</span>
          </div>
        </div>
        <el-button size="small" type="primary" v-if="!drag && type == 'default'"
          >上传文件</el-button
        >
        <div
          slot="tip"
          class="el-upload__tip"
          v-else-if="!drag && type == 'mp3'"
        >
          支持文件格式：.mp3，单个文件不能超过20M。
        </div>
        <div
          slot="tip"
          class="el-upload__tip"
          v-else-if="!drag && type == 'image' && !imgSize"
        >
          支持扩展名： .jpg, .jpeg, .png。
        </div>
        <div
          slot="tip"
          class="el-upload__tip"
          v-else-if="!drag && type == 'image' && imgSize"
        >
          建议尺寸：200px*200px
        </div>
        <div
          slot="tip"
          class="el-upload__tip"
          v-else-if="drag && type == 'excel'"
        >
          支持扩展名： .xls, .xlsx。
        </div>
        <div slot="tip" class="el-upload__tip" v-else-if="drag && type == 'txt'">
          支持扩展名：.txt
        </div>
        <div slot="tip" class="el-upload__tip" v-else>
          支持扩展名： .doc, .docx, .xls, .xlsx, .jpg, .jpeg, .png, .txt, .pdf。
        </div>
        <i class="el-icon-plus" v-if="!drag && type == 'image'"></i>
      </el-upload>
    </div>
  </div>
</template>

<script>
import cropper from "@/components/form/cropper";
export default {
  components: {
    cropper,
  },
  data() {
    return {
      hideUpload: false,
      fileArray: [],
      anotherData: {
        fileType: this.type,
      },
    };
  },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    directory: {
      type: String,
      default: "",
    },
    max: {
      type: Number,
      default: 1,
    },
    list: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "",
    },
    fileType: {
      type: String,
      default: "",
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    fit: {
      type: String,
      default: "",
    },
    drag: {
      type: Boolean,
      default: false,
    },
    unique: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    up_fz: {
      type: String,
      default: "",
    },
    accepts: {
      type: String,
      default: "",
    },
    imgSize:{
      type: Boolean,
      default: false
    }
  },
  watch: {
    list(value) {
      this.getFileArray(value);
    },
  },
  created() {
    if (!this.unique) {
      this.anotherData.uniqueCode = this.unique;
    }
  },
  mounted() {
    this.getFileArray(this.list);
  },
  methods: {
    getFileArray(value) {
      var getListImg = [];
      if (value && value.length != 0) {
        for (var i = 0; i < value.length; i++) {
          getListImg.push({
            name: value[i],
            url: value[i],
          });
        }
      }
      this.fileArray = [...getListImg];
      this.hideUpload = this.fileArray.length >= this.max;
    },
    beforeAvatarUpload(file) {
      console.log(file,"222222222222222");
      if (this.type == "mp3") {
        console.log(1.1);
        const isMP3 = file.type === "audio/mp3";
        const isLt10M = file.size / 1024 / 1024 < 20;
        if (!isMP3) {
          this.$message.error("只支持 MP3 格式!");
        }
        if (!isLt10M) {
          this.$message.error("上传 MP3 文件大小不能超过 20MB!");
        }
        return isMP3 && isLt10M;
      } else if (this.type == "image") {
        console.log(1.2);
        const isJPG = file.type === "image/jpeg" || file.type === "image/png";
        const isLt10M = file.size / 1024 / 1024 < 10;
        console.log(file.size / 1024 / 1024 )

        if (!isJPG) {
          this.$message.error("上传图片只能是 JPG 和 PNG 格式!");
        }
        if (!isLt10M) {
          this.$message.error("上传图片大小不能超过 10MB!");
        }
        console.log(isJPG && isLt10M);
        return isJPG && isLt10M;
      } else if (this.type == "file") {
        console.log(1.3);
        let name = file.name.split(".");
        let type = name[name.length - 1];
        let isFile =
          ["doc", "docx", "xls", "xlsx", "jpg", "txt","jpeg", "png", "pdf"].indexOf(
            type
          ) > -1;
        let isLt50M = file.size / 1024 / 1024 < 50;

        if (!isFile) {
          this.$message.error(
            "上传文件只能是doc、docx、xls、xlsx、jpg、jpeg、png、txt 和 pdf格式!"
          );
        }
        if (!isLt50M) {
          this.$message.error("上传文件大小不能超过 50MB!");
        }
        return isFile && isLt50M;
      } else if (this.type == "excel") {
        console.log(1.4);
        let name = file.name.split(".");
        let type = name[name.length - 1];
        let isFile = ["xls", "xlsx"].indexOf(type) == -1;
        let isLt50M = file.size / 1024 / 1024 < 50;
        if (isFile) {
          this.$message.error("上传文件只能是xls、xlsx格式!");
        }
        if (!isLt50M) {
          this.$message.error("上传文件大小不能超过 50MB!");
        }
        return !isFile && isLt50M;
      } else if(this.type == "txt") {
          let name = file.name.split(".");
         let type = name[name.length - 1];
         let isFile = ["txt"].indexOf(type) == -1;
        if (isFile) {
          this.$message.error("上传文件只能是txt格式!");
        }
      }
    },
    handleAvatarSuccess(response, file, fileList) {
      console.log(2);
      // 上传图片
      if (this.type == "mp3") {
        if (response.code == 0) {
          this.fileArray.push({
            url: response.data.visitURL,
            name: file.name,
          });
        }
        this.$emit("getNewList", this.fileArray);
      } else if (this.type == "image") {
        if (response.code == 0) {
          this.fileArray.push({
            url: response.data.visitURL,
            name: file.name,
            size: file.size,
          });
        }
        this.$emit("getNewList", this.fileArray);
      } else if (this.type == "txt") {
           let name = response.data.visitURL.split(".");
         let type1 = name[name.length - 1];
         let isFile = ["txt"].indexOf(type1) == -1;
        if (response.code == 0&&isFile) {
          this.fileArray=[]
           this.$emit("getNewList", this.fileArray);
        }else{
        this.fileArray.push({
            url: response.data.visitURL,
            name: file.name,
            size: file.size,
          });
          this.$emit("getNewList", this.fileArray);
        }
      }else {
        if (response.code == 0) {
          this.fileArray.push({
            url: response.data.visitURL,
            name: file.name,
          });
        }
        this.$emit("getNewList", this.fileArray);
      }
      this.hideUpload = fileList.length >= this.max;
    },
    handleRemove(file, fileList) {
      console.log(3);
      // if (!(fileList && fileList.length > 0)) {
      //   return;
      // }
      let url = file.url;
      let detection = true;
      if (url) detection = url.indexOf("blob") != -1;
      if (detection) {
        this.fileArray.forEach((item, i, arr) => {
          if (item.url === file.url) {
            this.fileArray.splice(i, 1);
          }
        });
      } else {
        this.fileArray.forEach((item, i, arr) => {
          if (item.url === file.url) {
            this.fileArray.splice(i, 1);
          }
        });
      }

      this.hideUpload = fileList.length >= this.max;
      this.$emit("getNewList", this.fileArray);
    },
    remove_pic() {
      document
        .querySelector(".eeupload")
        .querySelector(".el-upload-list__item-delete")
        .click();
      setTimeout(() => {
        document.querySelector(".eeupload").querySelector(".el-upload").click();
      }, 1000);
    },
    getNewUrl(val) {
      console.log(val);
      let fileObj = [{ name: val, url: val }];
      this.$emit("getNewList", fileObj);
    },
  },
};
</script>
<style scoped>
.uploads {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.upload-demo {
  flex: 1 1 auto;
  min-width: 360px;
  padding-right: 20px;
}
.upload_alert {
  /* flex: 0 0 auto; */
  /* width: 260px;
  height: 115px; */
  background-color: #f8f9fd;
  border-radius: 4px;
  padding: 20px;
  color: #8890a7;
  font-size: 14px;
  line-height: 26px;
  display: flex;
  align-items: flex-start;
}
.el-upload__tip{
  margin-top: 20px!important;
}
.up_alert_i {
  margin-right: 8px;
  line-height: 26px;
}
.el-upload-dragger .el-upload__text {
  color: #8890a7;
}
.el-upload-dragger .el-upload__text em {
  color: #264dd9;
}
.el-upload-dragger .el-icon-upload {
  margin: 35px 0 16px;
  line-height: 40px;
}
</style>
<style>
.hide .el-upload--picture-card {
  display: none;
}
.up_fz {
  color: #a9aec0;
}
</style>
