<template>
  <div>
    <!-- <div>
      <filterSearch
        ref="filter"
        class="gas_order_filter"
        :data="filterData"
        @serach="search"
      />
    </div> -->
    <vueTable
      ref="table"
      :headers="headers"
      :rows="datas"
      :selection="false"
      :pagination="pagination"
      :isPage="true"
      :index="true"
      hasScroll
      class="table"
      :tableHeight="650"
      @sizechange="sizechange"
    >
    <template v-slot:operations="{ row }">
         <el-button type="text" class="primary-del" @click="detail(row)">
          详情
        </el-button>
    </template>
    </vueTable>
  </div>
</template>
<script>
//import引入的组件
import vueTable from "../../../components/table/table.vue";
// import filterSearch from "../../../components/table/table_filter.vue";
import { getBSoutListApi } from "../../../api/user";
export default {
  components: {
    vueTable,
    // filterSearch
  },
  data() {
    return {
      filterData: [
        {
          label: "注销用户名",
          type: "input",
          key: "nickName",
          placeholder: "请输入注销用户名",
        },
      ],
      searchData: {
        nickName: "",
      },
      headers: [
        
       {
          prop: "id",
          label: "ID",
          width: "",
        },
        {
          prop: "nickName",
          label: "注销用户名",
          width: "",
        },
        {
          prop: "time",
          label: "注销时间",
          width: "",
        },
        // {
        //   label: "操作",
        //   width: 220,
        //   type: "operations",
        // },
      ],
      datas: [{}],
      pagination: {
        page: 1,
        rowsPerPage: 10,
        total: 0,
      },
    };
  },
  //方法集合
  methods: {
    search(value) {
      this.searchData.nickName = value.nickName;
      (this.pagination.rowsPerPage = 10), (this.pagination.page = 1);
      this.getBSoutListApi();
    },
    //获取用户信息
    async getBSoutListApi() {
      let res = await getBSoutListApi(
        this.pagination.rowsPerPage,
        this.pagination.page,
        this.searchData
      );
      if (res.data.code == "000") {
        this.datas = res.data.results;
        this.pagination.total = res.data.totalCount;
      }
    },
    sizechange(obj) {
      this.pagination.rowsPerPage = obj.rowsPerPage;
      this.getBSoutListApi();
    },
  },
  //生命周期 - 挂载前
  created() {
    this.getBSoutListApi();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang='scss' scoped>
</style>

