<template>
  <div>
    <el-button type="primary" size="default" @click="openDialog"
      >新增</el-button
    >
    <sys-dialog
    :visible="visible"
    :title="title"
    :width="width"
    :height="height"
    @onClose="onClose"
    @onConfirm="onConfirm"
    >
    <div slot="content">
        内容
    </div>
    </sys-dialog>
  </div>
</template>

<script>
import SysDialog from "../../components/SysDialog";
export default {
  components: {
    SysDialog,
  },
  data() {
    return {
      visible: false,
      title:'新增用户',
      width:600,
      height:200
    };
  },
  methods: {
    openDialog() {
      this.visible = true;
    },
    onClose(){
         this.visible = false;
    },
    onConfirm(){
        this.visible = false;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>