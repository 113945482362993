import http from '../http/request'
// 获取专家列表
export async function getBSextListApi(pageSize, currentPage, parm) {
    return await http.post(`/backStage/getBSextList/${pageSize}/${currentPage}`, parm)
}
//专家信息修改
export async function updateWdExpert(parm) {
    return await http.post(`/backStage/updateWdExpert`, parm)
}
// 获取专家审核列表
export async function getExamineListApi(pageSize, currentPage, parm) {
    return await http.post(`/backStage/getExamineList/${pageSize}/${currentPage}`, parm)
}
//专家审核
export async function updateWdExpertTempWeb(parm) {
    return await http.get(`/backStage/updateWdExpertTempWeb`, parm)
}