import http from '../http/request'
// 获取专家列表
export async function getOrderListApi(pageSize,currentPage,parm) {
    return await http.post(`/backStage/getOrderList/${pageSize}/${currentPage}`, parm)
}
//退单
export async function backOrderById(parm) {
    return await http.get(`/backStage/backOrderById`, parm)
}
//完成
export async function finshOrderById(parm) {
    return await http.get(`/backStage/finshOrderById`, parm)
}