<template>
  <div>
    <div>
      <filterSearch
        ref="filter"
        class="gas_order_filter"
        :data="filterData"
        @serach="search"
        :isCount="true"
        @onCount="onCount"
      />
    </div>
    <vueTable
      ref="table"
      :headers="headers"
      :rows="datas"
      :selection="true"
      :pagination="pagination"
      :isPage="true"
      :index="true"
      hasScroll
      class="table"
      @sizechange="sizechange"
      @selectnum="selectnum"
    >
    </vueTable>
     <sysDialog
      :title="Dialog.title"
      :visible="Dialog.visible"
      :width="Dialog.width"
      :height="Dialog.height"
      :isfoot="Dialog.isfoot"
      @onClose="onClose"
      :top="20"
    >
      <div slot="content">
          <div style="width:100%">
          <DefaultForm
          ref="addForm"
          slot="content"
          :data="formData"
          :defaultData="defaultData"
          labelPosition="left"
          >
          </DefaultForm>
          </div>
      </div>
    </sysDialog>
  </div>
</template>
<script>
//import引入的组件
import vueTable from "../../../components/table/table.vue";
import filterSearch from "../../../components/table/table_filter.vue";
import sysDialog from "../../../components/SysDialog.vue" 
import DefaultForm from "../../../components/form/default_form.vue";
import { Message } from "element-ui";
import { getParentListApi, totalPrice } from "../../../api/popularize";
export default {
  components: {
    vueTable,
    sysDialog,
    DefaultForm,
    filterSearch,
  },
  data() {
    return {
      defaultData: {
        totalprice:""
     },
      formData: [
    {
        label: "推广总价格",
        type: "input",
        placeholder: "请输入推广总价格",
        key: "totalprice",
        width: "100%",
        suffixText:"元", 
        suffix:true,
        disabled: true,
    }, 
      ],
      detailInfo: {},
      Dialog: {
        title: "推广价格计算",
        visible: false,
        width: 300,
        height: 100,
        isfoot: false,
      },
      countList: [],
      filterData: [
        {
          label: "用户名",
          type: "input",
          key: "userName",
          placeholder: "请输入用户名",
        },
        {
          label: "推广者名字",
          type: "input",
          key: "parentName",
          placeholder: "请输入推广者名字",
        },
      ],
      searchData: {
        userName: null,
        parentName: null,
      },
      headers: [
        {
          prop: "advisoryName",
          label: "专家或者倾听者的姓名",
          width: "",
        },
        {
          prop: "userName",
          label: "用户名",
          width: "",
        },
        //   {
        //   prop: "experId",
        //   label: "用户名",
        //   width: "",
        // },
        {
          prop: "parentName",
          label: "推广者名字",
          width: "",
        },
        {
          prop: "id",
          label: "ID",
          width: "",
        },
        // {
        //   prop: "type",
        //   label: "交易类型",
        //   width: "",
        // },
        {
          prop: "orderTime",
          label: "时间",
          width: "",
        },
        {
          prop: "price",
          label: "实际支付价格",
          width: "",
        },
        // {
        //   label: "操作",
        //   width: 220,
        //   type: "operations",
        // },
      ],
      datas: [{}],
      pagination: {
        page: 1,
        rowsPerPage: 10,
        total: 0,
      },
    };
  },
  //方法集合
  methods: {
    search(value) {
      this.searchData.userName = value.userName || null;
      this.searchData.parentName = value.parentName || null;
      (this.pagination.rowsPerPage = 10), (this.pagination.page = 1);
      this.getParentListApi();
    },
    //获取用户信息
    async getParentListApi() {
      let res = await getParentListApi(
        this.pagination.rowsPerPage,
        this.pagination.page,
        this.searchData
      );
      if (res.data.code == "000") {
        res.data.results.forEach((item) => {
          item.withdrawStatus == 1
            ? (item.withdrawStatus = "待审核")
            : item.withdrawStatus == 2
            ? (item.withdrawStatus = "提现成功")
            : item.withdrawStatus == 3
            ? (item.withdrawStatus = "拒绝提现")
            : (item.withdrawStatus = "---");
        });
        this.datas = res.data.results;
        this.pagination.total = res.data.totalCount;
      }
    },
    selectnum(e) {
      let arr = e;
      let newList = [];
      arr.forEach((item) => {
        newList.push(item.price);
      });
      this.countList = newList;
    },
     onCount() {
        this.countBtn()
    },
    async countBtn(){
         let res = await totalPrice(this.countList);
      if (res.data.code == "000") {
        this.Dialog.visible=true
        this.defaultData.totalprice=res.data.result
      }
    },
    sizechange(obj) {
      this.pagination.rowsPerPage = obj.rowsPerPage;
      this.getParentListApi();
    },
    onClose() {
      this.Dialog.visible = false;
    },
    onConfirm() {},
  },

  //生命周期 - 挂载前
  created() {
    this.getParentListApi();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang='scss' scoped>
.container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 15px;
}
.el-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.el-row {
  margin-bottom: 30px;
  font-size: 16px;
}
.foot_box {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
</style>

