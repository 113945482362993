<template>
  <div>
    <el-form
      :inline="true"
      ref="formInline"
      :model="formInline"
      class="demo-form-inline formsearch"
      :label-position="labelPosition"
      :rules="rules"
    >
      <el-form-item
        :label="item.label"
        v-for="(item, index) in data"
        :key="index + 500"
        :prop="item.key"
        :style="{
          margin: '0 10px !important',
          width: `calc( ${item.width ? item.width : '50%'} - 20px)`,
          'padding-bottom': item.type == 'label' ? '0' : '25px',
        }"
      >
        <!-- 插槽 -->
        <template v-if="item.type == 'slot'">
          <slot :name="item.slot"></slot>
        </template>
        <!-- 标题 -->
        <div class="default-form-title" v-if="item.type == 'label'">
          <span class="default-form-title-tag"></span>
          <div class="default-form-title-name" style="font-size: 20px">
            {{ item.placeholder }}
          </div>
          <el-tooltip placement="right-start" v-if="item.tip">
            <div slot="content">
              <div class="tooltip_title">{{ item.title }}</div>
              <div
                v-for="(msg, idx) in item.message"
                :key="idx + 100"
                class="tooltip_message"
              >
                {{ msg.msg }}
              </div>
            </div>
            <span class="dark-image"></span>
          </el-tooltip>
        </div>
        <!-- 按钮选择 -->
        <el-input
          v-model="formInline[item.key]"
          placeholder="请选择"
          @click.native="onselect(item)"
          v-if="item.type == 'btnselect'"
        >
          <el-button
            slot="append"
            @click="onselect(item)"
            style="background-color: #0c77d4; color: #f8f9fd"
            >选择</el-button
          >
        </el-input>
        <!-- 密码重置 -->
        <el-input
          v-model="formInline[item.key]"
          placeholder="请输入"
          v-if="item.type == 'btnInput'"
          :disabled="item.disabled ? item.disabled : false"
        >
          <el-button
            slot="append"
            @click="onInput(item)"
            :disabled="item.btnDis ? item.btnDis : false"
            :style="
              item.btnDis
                ? 'background-color: #9bc4cb; color: #f8f9fd;'
                : 'background-color: #378897; color: #f8f9fd'
            "
            >{{ item.btnText }}</el-button
          >
        </el-input>

        <!-- 输入框 -->
        <el-input
          v-if="item.type == 'input'"
          :type="item.type2"
          :min="item.min"
          :max="item.max"
          :minlength="item.minlength"
          :maxlength="item.maxlength"
          v-model="formInline[item.key]"
          :placeholder="item.placeholder"
          :show-word-limit="item.showword"
          :readonly="item.readonly ? item.readonly : false"
          :disabled="item.disabled ? item.disabled : false"
          @blur="BlurInput(item,formInline[item.key])"
        >
          <template v-if="item.suffix" slot="append">{{
            item.suffixText 
          }}</template>
        </el-input>
        <!-- 密码框 -->
        <el-input
          v-else-if="item.type == 'password'"
          type="password"
          v-model="formInline[item.key]"
          autocomplete="off"
          :placeholder="item.placeholder"
          :disabled="item.disabled ? item.disabled : false"
        ></el-input>
        <!-- 日期 type2:date,datetime,daterange-->
        <el-date-picker
          v-else-if="item.type == 'date'"
          :value-format="item.valueFormat || 'yyyy-MM-dd'"
          :format="item.format"
          v-model="formInline[item.key]"
          :type="item.type2"
          :style="{width:item.datewidth}"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :placeholder="item.placeholder"
          :default-time='item.defaultime'
          :disabled="item.disabled ? item.disabled : false"
          :picker-options="item.pickerOptions"
          class="form-date"
          @change="getime(item,formInline[item.key])"
        ></el-date-picker>
        <!-- 时间选择器 -->
        <el-time-picker
          v-else-if="item.type == 'time'"
          style="width:100%"
          v-model="formInline[item.key]"
          :format="item.format"
          :value-format="item.valueFormat || 'HH:mm:ss'"
          placeholder="任意时间点">
        </el-time-picker>
        <!-- 级联选择器 -->
        <el-cascader
          class="cascader_set_s"
          :popper-class="item.popperClass"
          v-else-if="item.type == 'cascader'"
          v-model="formInline[item.key]"
          :options="item.options"
          :props="{
            label: item.defpropslabel ? item.defpropslabel : 'label',
            value: item.defpropsvalue ? item.defpropsvalue : 'value',
            children: item.defpropschildren
              ? item.defpropschildren
              : 'children',
            multiple: item.multiple ? true : false,
            checkStrictly: item.props ? true : false,
          }"
          :placeholder="item.placeholder"
          :disabled="item.disabled ? item.disabled : false"
        ></el-cascader>
        <!-- 下拉框 -->
        <el-select
          v-else-if="item.type == 'select'"
          v-model="formInline[item.key]"
          :value-key="item.value"
          :placeholder="item.placeholder"
          :collapse-tags="item.collapsetags ? true : false"
          :multiple="item.multiple ? true : false"
          :disabled="item.disabled ? item.disabled : false"
          :filterable="item.filterable"
          :valueKey="item.valueKey || ''"
          class="cascader_set_s"
          @change="onselect(item, formInline[item.key])"
        >
          <el-option
            v-for="(it, idx) in item.arr"
            :key="idx + 600"
            :label="item.labelname ? it[item.labelname] : it.label"
            :value="item.valueKey ? it : item.value ? it[item.value] : it.value"
          ></el-option>
        </el-select>
                <!-- 下拉框 -->
        <el-select
          v-else-if="item.type == 'selectt'"
          v-model="formInline[item.key]"
          :value-key="item.value"
          :placeholder="item.placeholder"
          :collapse-tags="item.collapsetags ? true : false"
          :multiple="item.multiple ? true : false"
          :disabled="item.disabled ? item.disabled : false"
          :filterable="item.filterable"
          :valueKey="item.valueKey || ''"
          class="cascader_set_s"
          @change="onselectt(item, formInline[item.key])"
        >
          <el-option
            v-for="(it, idx) in item.arr"
            :key="idx + 600"
            :label="item.labelname ? it[item.labelname] : it.label"
            :value="item.valueKey ? it : item.value ? it[item.value] : it.value"
          ></el-option>
        </el-select>
        <!-- 多行文本框 -->
        <el-input
          v-if="item.type == 'textarea'"
          type="textarea"
          v-model="formInline[item.key]"
          :maxlength="item.maxlength"
          :placeholder="item.placeholder"
          :disabled="item.disabled ? item.disabled : false"
          :show-word-limit="item.showWordlimit"
        ></el-input>
        <el-rate
          v-if="item.type == 'rate'"
          v-model="formInline[item.key]"
          :disabled="item.Isdisabled"
          show-score
          :text-color="item.textColor"
          :score-template="
            '{ value }' + '(共' + formInline[item.num] + '人评价)'
          "
        >
        </el-rate>
        <!-- 只展示图片 -->
        <img
          v-if="item.type == 'imgShow'"
          style="width: 150px; height: 150px; border-radius: 4px"
          :src="formInline[item.key]"
          alt=""
        />
        <!-- 上传图片 -->
        <upload-file
          v-if="item.type == 'uploadFile'"
          :class="item.type2 === 'image' ? 'uploadImg' : ''"
          :multiple="false"
          :max="item.max ? item.max : 1"
          :type="item.type2"
          :readOnly="false"
          :list="uploadList[item.key]"
          @getNewList="getNewList($event, item.key, item.max, item.type)"
          :drag="item.drag"
          :imgSize="item.imgSize"
        ></upload-file>
        <!-- <uploadfile-multiple
          v-if="item.type == 'oneByOneFile'"
          :class="item.type2 === 'image' ? 'uploadFiles' : ''"
          :multiple="false"
          :max="item.max ? item.max : 1"
          :type="item.type2"
          :readOnly="false"
          :list="uploadList[item.key]"
          @getNewList="getNewList($event, item.key, item.max, item.type)"
          :drag="item.drag"
          :filesTitle="item.filesTitle"
        ></uploadfile-multiple> -->
        <!-- 上传视频 -->
        <upload-video
                v-if="item.type == 'uploadVideoFile'"
                class="uploadImg"
                :multiple="false"
                :max="item.max ? item.max : 1"
                :type="item.type2"
                :readOnly="false"
                :list="uploadList[item.key]"
                @getNewList="getNewList($event, item.key, item.max, item.type)"
                :drag="item.drag"
                :imgSize="item.imgSize"
        ></upload-video>
             <!-- 单选 -->
        <div v-if="item.type == 'radioo'">
          <el-radio-group
            v-model="formInline[item.key]"
            @change="
              (label,childItem) => {
                changeRadioo(label, item,childItem);
              }
            "
          >
            <el-radio
              v-for="(childItem, childIndex) in item.arr"
              :key="childIndex"
              :label="childItem.value"
             :disabled="item.disabled ? item.disabled:false"
              :border="childItem.border ? childItem.border : false"
              >{{ childItem.label }}</el-radio
            >
          </el-radio-group>
        </div>
        <!-- 单选 -->
        <div v-if="item.type == 'radio'">
          <el-radio-group
            v-model="formInline[item.key]"
            @change="
              (label) => {
                changeRadio(label, item);
              }
            "
          >
            <el-radio
              v-for="(childItem, childIndex) in item.arr"
              :key="childIndex"
              :label="childItem.value"
             :disabled="item.disabled ? item.disabled:false"
              :border="childItem.border ? childItem.border : false"
              >{{ childItem.label }}</el-radio
            >
          </el-radio-group>
        </div>
        <!-- 单选，用户管理Ta的油价 -->
        <div v-if="item.type == 'radioTa'">
          <el-radio-group
                  v-model="formInline[item.key]"
                  @change="
              (label) => {
                changeRadioTa(label, item,item.child);
              }
            "
          >
            <el-radio
                    v-for="(childItem, childIndex) in item.arr"
                    :key="childIndex"
                    :label="childItem.value"
                    :border="childItem.border ? childItem.border : false"
            >{{ childItem.label }}
              <span v-if="childItem.remark&&childItem.remark!=''"
              style="color: red">{{childItem.remark}}</span>
            </el-radio
            >
          </el-radio-group>
          <div v-show="TaInput">
            <div class="Ta_chirld_clect" v-if="item.child.arr">
              <div :class="childItem.class"
                   name="childItemName"
                   v-for="(childItem,index) of item.child.arr" :key='index'
              @click="setChirldArr(item.child,childItem.value,index)">
                {{childItem.label}}
              </div>
            </div>
            <div style="margin-top: 30px">
              <table class="chirld_table">
                <tr v-if="item.child.title">
                  <td v-for="(childTitle,s) of item.child.title" :key='s'>{{childTitle}}</td>
                </tr>
                <tr v-for="(childArr,index) of chirldArr" :key='index'>
                  <td v-for="(tem,i) of childArr" :key='i'>
                    <span v-if="!tem.check">{{tem.label}}</span>
                    <input v-if="tem.check" :value="tem.label" @keyup="getValues($event,item,index,i)" placeholder="请输入价格"/>
                  </td>
                </tr>
              </table>
            </div>
<!--            <div style="margin-top: 30px" v-for="(childArr,index) of item.child.arr">-->
<!--              <span>{{childArr.name}}</span>-->
<!--              <span>{{childArr.systemPrice}}</span>-->
<!--              <span>{{childArr.custom}}</span>-->
<!--            </div>-->
          </div>
        </div>
        <!-- 多选 -->
        <div v-if="item.type == 'checkbox'">
          <el-checkbox-group
            v-model="formInline[item.key]"
            @change="
              (label) => {
                changeCheckbox(label, item);
              }
            "
          >
            <el-checkbox
              v-for="(childItem, childIndex) in item.arr"
              :key="childIndex"
              :label="childItem.value"
              :border="childItem.border ? childItem.border : false"
              >{{ childItem.label }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
             <slot
            v-if="item.type === 'slot'"
            name="switchslot"
          ></slot>
        <!-- 按钮 -->
        <el-button
          v-if="item.type == 'Button'"
          class="form_c"
          @click="buttonClick"
          :disabled="item.disabled ? item.disabled : false"
          >{{ item.labelButton }}</el-button
        >
        <LotAdd
          v-if="item.type == 'addlot'"
          :itemkey="item.key"
          @getValue="getLotAdd"
        ></LotAdd>
        <DropTree
          v-if="item.type == 'droptree'"
          :itemkey="item.key"
          :data="item.arr"
          @getValue="getDropTree"
        >
        </DropTree>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import LotAdd from "@/components/form/lot_add";
import DropTree from "@/components/form/drop_tree";
import UploadFile from "@/components/form/upload_file";
// import UploadfileMultiple from "@/components/form/uploadFiles";
import UploadVideo from "@/components/form/upload_video";
import $ from 'jquery'
export default {
  components: {
    LotAdd,
    DropTree,
    UploadFile,
    // UploadfileMultiple,
    UploadVideo,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    labelPosition: {
      type: String,
      default: "top",
    },
    rules: {
      type: Object,
      default: () => {},
    },
    defaultData: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    formInline: {},
    formData: [],
    uploadList: {},
    TaInput:false,
    chirldArr:[],
    checkIndex:1,
  }),
  computed: mapState({
    mapPoint: (state) => state.mapPoint,
  }),
  watch: {
    defaultData: {
      handler(value) {
        if (JSON.stringify(value) != "{}") {
          // console.log(value);
          this.formInline = JSON.parse(JSON.stringify(value));
          this.$forceUpdate();
        } else {
          let temp = {};
          this.data.forEach((e) => {
            temp[e.key] = "";
          });
          // console.log(temp);
          this.formInline = temp;
        }
        this.data.forEach((item) => {
          if (item.type == "uploadFile" || item.type == "oneByOneFile"||item.type == "uploadVideoFile") {
            let upobj = JSON.parse(JSON.stringify(this.uploadList));
            upobj[item.key] = Array.isArray(this.formInline[item.key])
              ? this.formInline[item.key]
              : this.formInline[item.key] == ""
              ? []
              : [this.formInline[item.key]];
            this.uploadList = JSON.parse(JSON.stringify(upobj));
          }
        });
        // console.log(this.formInline);
      },
      deep: true,
      immediate: true,
    },
    mapPoint(item) {
      if (item.name) {
        this.formInline.address = item.name + " - " + item.address;
      }
    },
  },
  mapPoint(item) {
    if (item.name) {
      this.formInline.address = item.name + " - " + item.address;
      this.formInline.latitude_longitude = item.location;
    }
  },
  created() {
    // console.log(this.defaultData);
    // if (this.defaultData) {
    //   this.formInline = this.defaultData;
    // } else {
    //   let temp = {};
    //   this.data.forEach((e) => {
    //     console.log(e);
    //     temp[e.key] = "";
    //   });
    //   console.log(temp);
    //   this.formInline = temp;
    // }
  },
  methods: {
    remove_data() {
      this.$refs["formInline"].resetFields();
      this.$emit("serach", "");
    },
    getLotAdd(val) {
      this.formInline[val.itemkey] = val.list;
    },
    getDropTree(val) {
      this.formInline[val.itemkey] = val.list;
    },
    getNewList(val, key, max = 1, type) {
      let upobj = JSON.parse(JSON.stringify(this.uploadList));
      console.log(upobj,'')
      if (type == "uploadFile") {
        if (max == 1) {
          if (val && val.length == 1) {
            this.formInline[key] = val[0].url;
            // if(this.formInline.hasOwnProperty('imgSize')==true){
            //  this.formInline.imgSize=(val[0].size/1000).toFixed(2)
            //   }
            upobj[key] = [val[0].url];
          } else if (val && val.length == 0) {
            this.formInline[key] = "";
            // if(this.formInline.hasOwnProperty('imgSize')==true){
            //  this.formInline.imgSize=''
            //   }
            upobj[key] = [];
          }
        } else if (max > 1) {
          this.formInline[key] = val.map((item) => {
            return item.url;
          });
          upobj[key] = this.formInline[key];
        }
      } else {
        this.formInline[key] = val;
        upobj[key] = val;
      }
      this.uploadList = JSON.parse(JSON.stringify(upobj));
      this.$emit("uploadFinish", this.uploadList);
    },
    onselect(val, key) {
      this.$emit("onselect", val, key);
    },
    onselectt(val,key){
        let arrlist=val.arr
        for(var i=0;i<arrlist.length;i++){
        if(arrlist[i].value==key){
          console.log()
          this.$emit("onselectt", val,arrlist[i].label,arrlist[i].value);
        }
        }
    },
    onInput(val) {
      this.$emit("onInput", val);
    },
    BlurInput(val,b) {
      this.$emit("BlurInput", val,b);
    },
    getime(item,val){
      this.$emit('getime',item,val)
    },
    changeRadio(item, val) {
      this.$emit("changeRadio", val, item);
    },
        changeRadioo(item, val,val1) {
        let arrlist=val.arr
        for(var i=0;i<arrlist.length;i++){
        if(arrlist[i].value==item){
          this.$emit("changeRadioo", val, item,arrlist[i].oilType,arrlist[i].oilOriginalPrice);
        }
        }
     
    },
    changeRadioTa(item, val,child) {
      if(item==val.selectCondition){
        this.TaInput=true;
        this.chirldArr=child.arr[child.index].arr;
        this.checkIndex=child.index;
      }else {
        this.TaInput=false;
      }
      this.$emit("changeRadio", val, item);
    },
    setChirldArr(item,val,index){
      this.checkIndex=index;
      this.chirldArr=item.arr[index].arr;
      $("[name='childItemName']").removeClass("Ta_chirld_clect_div");
      $("[name='childItemName']").eq(index).addClass("Ta_chirld_clect_div");
    },
    getValues(val,item,index1,index2){
      item.child.arr[this.checkIndex].arr[index1][index2].label=val.currentTarget.value;
      console.log(item);
    },
    changeCheckbox(item, val) {
      this.$emit("changeCheckbox", val, item);
    },
    buttonClick() {
      this.$emit("buttonClick");
    },
  },
};
</script>

<style lang="scss" scoped>
.advanced_form {
  .el-form--label-top .el-form-item__label {
    padding: 10px 0 0 14px;
  }
  .el-select {
    width: 100%;
  }
  .advanced_form .el-cascader {
    width: 100%;
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
  .cascader_set_s {
    width: 100%;
    .el-tag__close.el-icon-close {
      background-color: #e6ebfe !important;
      color: #264dd9 !important;
      right: -4px !important;
    }
    .el-tag {
      background-color: #e6ebfe !important;
      border-radius: 4px !important;
      color: #264dd9 !important;
    }
    .el-input__inner {
      /* height: auto !important; */
      min-height: 34px !important;
    }
  }
}
.uploadFiles {
  width: 200px;
  height: 150px;
}
.dark-image {
  width: 16px;
  height: 16px;
  // background: url(../../../assets/image/list_ic_tips.png) no-repeat left bottom;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin-left: 8px;
}
.tooltip_title {
  color: #3e9ff4;
  font-size: 16px;
  background-color: #242c43;
  border-bottom: 1px solid #3f4864;
  padding-bottom: 8px;
  margin-bottom: 8px;
}
.tooltip_message {
  color: #a9afc7;
}
  .Ta_chirld_clect{
    display: flex;
    justify-content: flex-start;
    /*width: 200px;*/
    margin-top: 30px
  }
  .Ta_chirld_clect_div1{
    width: 100px;
    height: 30px;
    border: 1px solid gray;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
  }
.Ta_chirld_clect_div2{
  width: 100px;
  height: 30px;
  border: 1px solid gray;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
  .Ta_chirld_clect_div{
    background: gray;
    color: white;
    font-weight: bold;
  }
.chirld_table{
  width: 100%;
  border: 1px solid #bcc1d0;
  border-collapse: collapse;
  table-layout : fixed
}
  .chirld_table tr{
    width: 100%;
    height: 30px;
  }
.chirld_table tr td{
  text-align: center;
  border-bottom: 1px solid #bcc1d0;
}
.chirld_table tr td input{
  width: 100px;
  height: 30px;
  border: none;
  outline: none;
  text-align: center;
}
</style>
