<template>
  <div>
    <div>
      <filterSearch
        ref="filter"
        class="gas_order_filter"
        :data="filterData"
        @serach="search"
      />
    </div>
    <vueTable
      ref="table"
      :headers="headers"
      :rows="datas"
      :selection="false"
      :pagination="pagination"
      :isPage="true"
      :index="true"
      hasScroll
      class="table"
      @sizechange="sizechange"
    >
      <template v-slot:operations="{ row }">
        <el-button type="primary" size="mini" @click="pass(row)" v-if="row.withdrawStatus=='待审核'">
          允许
        </el-button>
          <el-button type="warning" size="mini" @click="refuse(row)" v-if="row.withdrawStatus=='待审核'">
          拒绝
        </el-button>
      </template>
    </vueTable>
        <sysDialog
      :title="Dialog.title"
      :visible="Dialog.visible"
      :width="Dialog.width"
      :height="Dialog.height"
      :isfoot="Dialog.isfoot"
      @onClose="onClose"
      @onConfirm="onConfirm"
      :top="20"
    >
      <div slot="content">
        <div class="container">
          <el-row>
            <el-col :span="12"
              ><div>余额：{{ detailInfo.balance }}</div></el-col
            >
            <el-col :span="12"
              ><div>银行卡号：{{ detailInfo.bankNum }}</div></el-col
            >
          </el-row>
          <el-row>
            <el-col :span="12"
              ><div>身份证号：{{ detailInfo.cardNum }}</div></el-col
            >
            <el-col :span="12"
              ><div>手机号：{{ detailInfo.phoneNum }}</div></el-col
            >
          </el-row>
          <el-row>
            <el-col :span="12"
              ><div>支付宝账号：{{ detailInfo.zhifubao }}</div></el-col
            >
          </el-row>
        </div>
      </div>
    </sysDialog>
  </div>
</template>
<script>
//import引入的组件
import vueTable from "../../../components/table/table.vue";
import filterSearch from "../../../components/table/table_filter.vue";
import sysDialog from "../../../components/SysDialog.vue"
import { Message } from "element-ui";
import { getBScashListApi ,getWdBanlanceByIdWeb,withCash} from "../../../api/cash";
export default {
  components: {
    vueTable,
    sysDialog,
    filterSearch,
  },
  data() {
    return {
      detailInfo:{},
       Dialog: {
        title: "提现详情",
        visible: false,
        width: 600,
        height: 200,
        isfoot: true,
      },
      filterData: [
        {
          label: "用户名",
          type: "input",
          key: "experId",
          placeholder: "请输入用户名",
        },
        {
          label: "提现状态",
          type: "select",
          key: "withdrawStatus",
          placeholder: "请选择提现状态",
          arr:[{
            value:"",
            label:"全部"
          },{
            value:1,
            label:"待审核"
          },{
            value:2,
            label:"提现成功"
          },{
            value:3,
            label:"拒绝提现"
          }]
        },
      ],
      searchData: {
        experId: null,
        withdrawStatus: null,
      },
      headers: [
        {
          prop: "id",
          label: "提现ID",
          width: "",
        },
        // {
        //   prop: "userId",
        //   label: "用户ID",
        //   width: "",
        // },
        //   {
        //   prop: "experId",
        //   label: "用户名",
        //   width: "",
        // },
        {
          prop: "tranDate",
          label: "提现日期",
          width: "",
        },
        // {
        //   prop: "type",
        //   label: "交易类型",
        //   width: "",
        // },
        {
          prop: "experId",
          label: "用户名",
          width: "",
        },
        {
          prop: "price",
          label: "提现金额",
          width: "",
        },
        {
          prop: "totalPrice",
          label: "实际到账金额",
          width: "",
        },
           {
          prop: "withdrawStatus",
          label: "提现状态",
          width: "",
        },
        {
          label: "操作",
          width: 220,
          type: "operations",
        },
      ],
      datas: [{}],
      pagination: {
        page: 1,
        rowsPerPage: 10,
        total: 0,
      },
    };
  },
  //方法集合
  methods: {
    search(value) {
      this.searchData.experId = value.experId||null;
      this.searchData.withdrawStatus = value.withdrawStatus||null;
      (this.pagination.rowsPerPage = 10), (this.pagination.page = 1);
      this.getBScashListApi();
    },
    //获取用户信息
    async getBScashListApi() {
      let res = await getBScashListApi(
        this.pagination.rowsPerPage,
        this.pagination.page,
        this.searchData
      );
      if (res.data.code == "000") {
        res.data.results.forEach((item) => {
              item.withdrawStatus ==1
            ? (item.withdrawStatus = "待审核")
            : item.withdrawStatus == 2
            ? (item.withdrawStatus = "提现成功")
            : item.withdrawStatus == 3
            ? (item.withdrawStatus = "拒绝提现")
            : (item.withdrawStatus = "---");
        });
        this.datas = res.data.results;
        this.pagination.total = res.data.totalCount;
      }
    },
    pass(row) {
      this.Dialog.visible=true  
      this.id=row.id
      this.type=1
      this.getWdBanlanceByIdWeb(row.userId)
    },
    async getWdBanlanceByIdWeb(id){
      let res=await getWdBanlanceByIdWeb(id)
      console.log(res.data,"2")
      if(res.data.code=="000"){
          this.detailInfo=res.data.result
    }
    },
    refuse(row){
     this.Dialog.visible=true  
      this.id=row.id
      this.type=2
      this.getWdBanlanceByIdWeb(row.userId)
    },
    sizechange(obj) {
      this.pagination.rowsPerPage = obj.rowsPerPage;
      this.getBScashListApi();
    },
    onClose(){
      this.Dialog.visible=false
    },
    async onConfirm(){
      if(this.type==1){
         let res= await withCash(2,this.id) 
         if(res.data.code=="000"){
             Message({
              type: "success",
              message: "允许提现成功!",
            });
            this.Dialog.visible=false
            this.getBScashListApi()
         }
      }else{
           let res= await withCash(3,this.id) 
         if(res.data.code=="000"){
             Message({
              type: "success",
              message: "拒绝提现成功!",
            });
            this.Dialog.visible=false
            this.getBScashListApi()
         }
      }
    },
  },
  
  //生命周期 - 挂载前
  created() {
    this.getBScashListApi();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang='scss' scoped>
.container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 15px;
}
.el-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.el-row {
  margin-bottom: 30px;
  font-size: 16px;
}
.foot_box {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
</style>

