<template>
  <div>
    <div>
      <filterSearch
        ref="filter"
        class="gas_order_filter"
        :data="filterData"
        @serach="search"
      />
    </div>
    <vueTable
      ref="table"
      :headers="headers"
      :rows="datas"
      :selection="false"
      :pagination="pagination"
      :isPage="true"
      :index="true"
      hasScroll
      class="table"
      @sizechange="sizechange"
    >
    <template v-slot:operations="{ row }">
         <el-button type="warning" size="mini" @click="detail(row)">
          发送通知
        </el-button>
    </template>
    </vueTable>
  </div>
</template>
<script>
//import引入的组件
import vueTable from "../../../components/table/table.vue";
import filterSearch from "../../../components/table/table_filter.vue";
import { getBSnotifyListApi ,notify} from "../../../api/notify";
import {Message} from "element-ui"
export default {
  components: {
    vueTable,
    filterSearch
  },
  data() {
    return {
      filterData: [
        {
          label: "用户名",
          type: "input",
          key: "nickName",
          placeholder: "请输入用户名",
        },
        // {
        //   label: "用户手机号",
        //   type: "input",
        //   key: "phoneNum",
        //   placeholder: "请输入用户手机号",
        // },
      ],
      searchData: {
        nickName: "",
      },
      headers: [
        {
          prop: "nickName",
          label: "用户名",
          width: "",
        },
        {
          prop: "content",
          label: "内容",
          width: "",
        },
        {
          prop: "time",
          label: "通知时间",
          width: "",
        },
        {
          prop: "isRead",
          label: "是否已读",
          width: "",
        },
        {
          label: "操作",
          width: 220,
          type: "operations",
        },
      ],
      datas: [{}],
      pagination: {
        page: 1,
        rowsPerPage: 10,
        total: 0,
      },
    };
  },
  //方法集合
  methods: {
    search(value) {
      this.searchData.nickName = value.nickName;
      (this.pagination.rowsPerPage = 10), (this.pagination.page = 1);
      this.getBSnotifyListApi();
    },
    //获取用户信息
    async getBSnotifyListApi() {
      let res = await getBSnotifyListApi(
        this.pagination.rowsPerPage,
        this.pagination.page,
        this.searchData
      );
      if (res.data.code == "000") {
        res.data.results.forEach((item) => {
          item.isRead == 0
            ? (item.isRead = "未读")
            : item.isRead == 1
            ? (item.isRead = "已读")
            : (item.isRead = "已读");
        });
        this.datas = res.data.results;
        this.pagination.total = res.data.totalCount;
      }
    },
    sizechange(obj) {
      this.pagination.rowsPerPage = obj.rowsPerPage;
      this.getBSnotifyListApi();
    },
   detail(row){
      this.$prompt("发送通知？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(async (value) => {
          if (!value.value) {
            Message({
              type: "warning",
              message: "内容不能为空",
            });
            return;
          } else {
          let obj = {
              userId:row.userId,
              count:value.value
            };
            let res = await notify(obj);
            if(res.data.code=="000"){
                console.log(23456)
             this.getBSnotifyListApi();
              Message({
              type: "success",
              message: "发送通知成功!",
            });
            }
          }
        });
    },
  },
  //生命周期 - 挂载前
  created() {
    this.getBSnotifyListApi();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang='scss' scoped>
</style>

