<template>
  <el-popover
    v-model="visible"
    placement="bottom"
    :visible-arrow="false"
    title
    :width="popWidth"
    trigger="click"
  >
    <Tree :data="data" :need_value="true" @getValue="getValue"></Tree>
    <el-input
      slot="reference"
      ref="init"
      :readonly="true"
      v-model="value"
      :placeholder="placeholder"
      @click="visible = true"
    ></el-input>
  </el-popover>
</template>

<script>
import Tree from "@/components/form/tree";
export default {
  props: {
    placeholder: {
      type: String,
      default: "请选择",
    },
    data: {
      type: Array,
      default: () => {
        [];
      },
    },
    itemkey: {
      type: String,
      default: "",
    },
  },
  components: {
    Tree,
  },
  data: () => ({
    value: "",
    visible: false,
    popWidth: 0,
  }),
  mounted() {
    this.getInitWidth();
    window.onresize = () => {
      this.getInitWidth();
    };
  },
  computed: {},
  created() {},
  methods: {
    getInitWidth() {
      let width = this.$refs.init.$el.offsetWidth;
      this.popWidth = width;
    },
    getValue(value) {
      this.value = value[0].label;
      this.visible = false;
      this.$emit("getValue", { itemkey: this.itemkey, list: value });
    },
  },
};
</script>

<style scoped>
</style>
