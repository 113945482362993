<template>
  <div>
    <div>
      <filterSearch
        ref="filter"
        class="gas_order_filter"
        :data="filterData"
        @serach="search"
      />
    </div>
    <vueTable
      ref="table"
      :headers="headers"
      :rows="datas"
      :selection="false"
      :pagination="pagination"
      :isPage="true"
      :index="true"
      hasScroll
      class="table"
      :tableHeight="650"
      @sizechange="sizechange"
    >
      <template v-slot:operations="{ row }">
        <el-button type="text" class="primary-del" @click="detail(row)">
          详情
        </el-button>
      </template>
    </vueTable>
    <sysDialog
      :title="Dialog.title"
      :visible="Dialog.visible"
      :width="Dialog.width"
      :height="Dialog.height"
      :isfoot="Dialog.isfoot"
      @onClose="onClose"
    >
      <div slot="content">
        <div class="container">
          <el-row>
            <el-col :span="24"
              ><div>
                <el-image
                  class="image"
                  :src="detailInfo.headimage"
                ></el-image></div
            ></el-col>
          </el-row>
          <el-row>
            <el-col :span="6"
              ><div>专家昵称：{{ detailInfo.expertName }}</div></el-col
            >
            <el-col :span="6"
              ><div>专家手机号：{{ detailInfo.phoneNum }}</div></el-col
            >
            <el-col :span="6"
              ><div>身份证号:{{ detailInfo.idcardNum }}</div></el-col
            >
            <el-col :span="6"
              ><div>支付宝账号:{{ detailInfo.zhifubao }}</div></el-col
            >
          </el-row>
          <el-row>
            <el-col :span="6"
              ><div>创建时间：{{ detailInfo.createDate }}</div></el-col
            >
            <el-col :span="6"
              ><div>银行卡号：{{ detailInfo.bankNum }}</div></el-col
            >
            <el-col :span="6"
              ><div>单次时长：{{ detailInfo.chatTime }}</div></el-col
            >
            <el-col :span="6"
              ><div>
                自动回复快捷短语：{{ detailInfo.customMessage }}
              </div></el-col
            >
          </el-row>
          <el-row>
            <el-col :span="6"
              ><div style="display: flex; align-items: center">
                资格证正面照：<el-image
                  style="width: 100px; height: 100px; z-index: 999"
                  :src="detailInfo.qucertImage"
                  preview-teleported
                  z-index="99999"
                  :preview-src-list="qucertsrcList"
                >
                </el-image></div
            ></el-col>
            <el-col :span="6"
              ><div style="display: flex; align-items: center">
                身份证正面：<el-image
                  style="width: 100px; height: 100px; z-index: 999"
                  :src="detailInfo.idcardImage"
                  preview-teleported
                  z-index="99999"
                  :preview-src-list="idcardsrcList"
                >
                </el-image></div
            ></el-col>
          </el-row>
          <el-row>
            <el-col :span="24"
              ><div>详细介绍：{{ detailInfo.introduce }}</div></el-col
            >
          </el-row>
          <el-row>
            <el-col :span="24"
              ><div>简介：{{ detailInfo.intro }}</div></el-col
            >
          </el-row>
          <el-row>
            <el-col :span="24"
              ><div>给用户的话：{{ detailInfo.giveUser }}</div></el-col
            >
          </el-row>
          <el-row>
            <el-col :span="24"
              ><div>拒绝理由：{{ detailInfo.reason }}</div></el-col
            >
          </el-row>
        </div>
        <div class="foot_box">
          <el-button type="primary" size="mini" @click="passBtn"
            >通过</el-button
          >
          <el-button type="warning" size="mini" @click="warnBtn"
            >拒绝</el-button
          >
        </div>
      </div>
    </sysDialog>
  </div>
</template>
<script>
//import引入的组件
import vueTable from "../../../components/table/table.vue";
import filterSearch from "../../../components/table/table_filter.vue";
import sysDialog from "../../../components/SysDialog.vue";
import { Message } from "element-ui";
import { getExamineListApi ,updateWdExpertTempWeb} from "../../../api/special";
export default {
  components: {
    vueTable,
    sysDialog,
    filterSearch
  },
  data() {
    return {
      Dialog: {
        title: "咨询师审核详情",
        visible: false,
        width: 1100,
        isfoot: false,
      },
      detailInfo: {},
      filterData: [
        {
          label: "用户名",
          type: "input",
          key: "expertName",
          placeholder: "请输入用户名",
        },
        {
          label: "用户手机号",
          type: "input",
          key: "phoneNum",
          placeholder: "请输入用户手机号",
        },
      ],
      searchData: {
        expertName: "",
        phoneNum: "",
      },
      headers: [
        {
          prop: "expertName",
          label: "用户名",
          width: "",
        },
        {
          prop: "phoneNum",
          label: "用户手机号",
          width: "",
        },
        {
          prop: "createDate",
          label: "申请时间",
          width: "",
        },
        {
          prop: "checkStatus",
          label: "审核状态",
          width: "",
        },
        {
          label: "操作",
          width: 220,
          type: "operations",
        },
      ],
      datas: [{}],
      qucertsrcList: [],
      idcardsrcList: [],
      pagination: {
        page: 1,
        rowsPerPage: 10,
        total: 0,
      },
    };
  },
  //方法集合
  methods: {
    search(value) {
      this.searchData.expertName = value.expertName;
      this.searchData.phoneNum = value.phoneNum;
      (this.pagination.rowsPerPage = 10), (this.pagination.page = 1);
      this.getExamineListApi();
    },
    //获取用户信息
    async getExamineListApi() {
      let res = await getExamineListApi(
        this.pagination.rowsPerPage,
        this.pagination.page,
        this.searchData
      );
      if (res.data.code == "000") {
        res.data.results.forEach((item) => {
          item.checkStatus == 0
            ? (item.checkStatus = "待审核")
            : item.checkStatus == 1
            ? (item.checkStatus = "通过")
            : item.checkStatus == 2
            ? (item.checkStatus = "拒绝")
            : (item.checkStatus = "---");
        });
        this.datas = res.data.results;
        this.pagination.total = res.data.totalCount;
      }
    },
    sizechange(obj) {
      this.pagination.rowsPerPage = obj.rowsPerPage;
      this.getExamineListApi();
    },
    detail(row) {
      this.detailInfo = row;
      this.qucertsrcList.push(row.qucertImage);
      this.idcardsrcList.push(row.idcardImage);
      this.Dialog.visible = true;
    },
    onClose() {
      this.Dialog.visible = false;
    },
    passBtn() {
        this.$confirm('确定通过该用户申请？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
            let obj = {
              checkStatus: 1,
              id: this.detailInfo.id,
              reason:"",
            };
            let res = await updateWdExpertTempWeb(obj);
            if(res.data.code=="000"){
               Message({
              type: "success",
              message: "通过成功!",
            });
            this.getExamineListApi()
            this.Dialog.visible=false
            }
        }).catch(() => {
          Message({
            type: 'info',
            message: '已取消操作'
          });          
        });
    },
    warnBtn() {
      this.$prompt("确定拒绝该用户申请？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(async (value) => {
          if (!value.value) {
            Message({
              type: "warning",
              message: "拒绝理由不能为空",
            });
            return;
          } else {
            let obj = {
              checkStatus: 2,
              id: this.detailInfo.id,
              reason:value.value,
            };
            let res = await updateWdExpertTempWeb(obj);
            if(res.data.code=="000"){
               Message({
              type: "success",
              message: "拒绝成功!",
            });
            this.getExamineListApi()
            this.Dialog.visible=false
            }
            // this.initData();
          }
        })
        .catch(() => {
          Message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
  },
  //生命周期 - 挂载前
  created() {
    this.getExamineListApi();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang='scss' scoped>
.container {
  width: 100%;
  height: 100%;
}
.image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.el-row {
  margin-bottom: 30px;
  font-size: 16px;
}
.foot_box {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
</style>

