<template>
  <el-dialog
    :top="top+ 'vh'"
    :title="title"
    :visible.sync="visible"
    :width="width + 'px'"
    :before-close="onClose"
  >
    <div class="container" :style="{ height: height + 'px' }">
      <slot name="content"></slot>
    </div>
    <span slot="footer" class="dialog-footer" v-if="isfoot">
      <el-button type="danger" @click="onClose" size="mini">取 消</el-button>
      <el-button type="primary" @click="onConfirm" size="mini">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "标题",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    isfoot: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 8,
    },
    width: {
      type: Number,
      default: 600,
    },
    height: {
      type: Number
    },
  },
  methods: {
    //关闭弹框
    onClose() {
      this.$emit("onClose");
    },
    //确认弹框
    onConfirm() {
      this.$emit("onConfirm");
    },
  },
};
</script>

<style lang="scss" scoped>
.container{
  overflow-x: initial;
  // overflow-y: auto;
}
.el-dialog__wrapper {
  ::v-deep .el-dialog {
    border-top-left-radius: 7px !important;
    border-top-right-radius: 7px !important;
    .el-dialog__header {
      border-top-left-radius: 7px !important;
      border-top-right-radius: 7px !important;
      background-color: #1890ff;
      .el-dialog__title {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
      }
      .el-dialog__close {
        color: #fff;
      }
    }
    .el-dialog__body {
      padding: 10px;
    }
    .el-dialog__footer {
      border-top: 1px solid #e8eaec !important;
      padding: 10px;
      display: flex;
      justify-content: center;
    }
  }
}
</style>