<template>
  <div>
    <div>
      <filterSearch
        ref="filter"
        class="gas_order_filter"
        :data="filterData"
        @serach="search"
      />
    </div>
    <vueTable
      ref="table"
      :headers="headers"
      :rows="datas"
      :selection="false"
      :pagination="pagination"
      :isPage="true"
      :index="true"
      hasScroll
      class="table"
      @sizechange="sizechange"
    >
      <template v-slot:operations="{ row }">
        <el-button type="text" class="primary-del" @click="detail(row)">
          详情
        </el-button>
      </template>
    </vueTable>
    <sysDialog
      :title="Dialog.title"
      :visible="Dialog.visible"
      :width="Dialog.width"
      :height="Dialog.height"
      :isfoot="Dialog.isfoot"
      @onClose="onClose"
    >
      <div slot="content">
        <div class="container">
          <el-row>
            <el-col :span="24"
              ><div>
                <el-image :src="detailInfo.headimage"></el-image></div
            ></el-col>
          </el-row>
          <el-row>
            <el-col :span="6"
              ><div>用户昵称：{{ detailInfo.nickName }}</div></el-col
            >
            <el-col :span="6"
              ><div>用户手机号：{{ detailInfo.phoneNum }}</div></el-col
            >
            <el-col :span="6"
              ><div>金币：{{ detailInfo.gold }}</div></el-col
            >
            <el-col :span="6"
              ><div>余额：{{ detailInfo.balance }}</div></el-col
            >
          </el-row>
          <el-row>
            <el-col :span="6"
              ><div>更新时间：{{ detailInfo.updateDate }}</div></el-col
            >
            <el-col :span="6"
              ><div>注册时间：{{ detailInfo.registerDate }}</div></el-col
            >
          </el-row>
          <el-row>
            <el-col :span="6"
              ><div>用户类型：{{ detailInfo.userType }}</div></el-col
            >
            <el-col :span="6"
              ><div>是否被封禁：{{ detailInfo.bannedStatus }}</div></el-col
            >
            <el-col :span="6"
              ><div>在线状态：{{ detailInfo.online }}</div></el-col
            >
          </el-row>
          <el-row>
            <el-col :span="6"
              ><div>封禁原因：{{ detailInfo.bannedResult }}</div></el-col
            >
          </el-row>
        </div>
        <div class="foot_box">
          <el-button type="warning" size="mini" @click="warnBtn"
            >警告用户</el-button
          >
          <el-button type="danger" size="mini" @click="banBtn"
            >封禁用户</el-button
          >
          <el-button type="success" size="mini" @click="unsealBtn"
            >解封用户</el-button
          >
          <el-button type="primary" size="mini" @click="editBtn"
            >修改余额</el-button
          >
          <el-button type="success" size="mini" @click="goldBtn"
            >修改金币</el-button
          >
        </div>
      </div>
    </sysDialog>
    <!-- <sysDialog
       :title="addDialog.title"
      :visible="addDialog.visible"
      :width="addDialog.width"
      :height="addDialog.height"
      @onClose="onClose"
      @onConfirm="onConfirm"
     >
     
    </sysDialog> -->
  </div>
</template>
<script>
//import引入的组件
import VueTable from "../../../components/table/table.vue";
import filterSearch from "../../../components/table/table_filter.vue";
import sysDialog from "../../../components/SysDialog.vue";
import { Message } from "element-ui";
import { getUserListApi, getBSuserById, operate,species } from "../../../api/user";
export default {
  components: {
    VueTable,
    filterSearch,
    sysDialog,
  },
  data() {
    return {
      // addDialog:{
      //   title: "用户详情",
      //   visible: false,
      //   width: 500,
      //   height: 300,
      //   isfoot: true,
      //   top:12
      // },
      Dialog: {
        title: "用户详情",
        visible: false,
        width: 1100,
        height: 500,
        isfoot: false,
      },
      filterData: [
        {
          label: "用户名",
          type: "input",
          key: "nickName",
          placeholder: "请输入用户名",
        },
        {
          label: "用户手机号",
          type: "input",
          key: "phoneNum",
          placeholder: "请输入用户手机号",
        },
      ],
      searchData: {
        nickName: "",
        phoneNum: "",
      },
      headers: [
        {
          prop: "nickName",
          label: "用户名",
          width: "",
        },

        {
          prop: "phoneNum",
          label: "用户手机号",
          width: "",
        },
        {
          prop: "bannedStatus",
          label: "帐号状态",
          width: "",
        },
        {
          prop: "registerDate",
          label: "注册时间",
          width: "",
        },
        {
          prop: "online",
          label: "在线状态",
          width: "",
        },
        {
          prop: "updateDate",
          label: "活跃时间",
          width: "",
        },
        {
          label: "操作",
          width: 220,
          type: "operations",
        },
      ],
      userType:"",
      datas: [{}],
      detailInfo: {},
      pagination: {
        page: 1,
        rowsPerPage: 10,
        total: 0,
      },
    };
  },
  //方法集合
  methods: {
    search(value) {
      this.searchData.nickName = value.nickName;
      this.searchData.phoneNum = value.phoneNum;
      (this.pagination.rowsPerPage = 10), (this.pagination.page = 1);
      this.getUserListApi();
    },
    //获取用户信息
    async getUserListApi() {
      let res = await getUserListApi(
        this.pagination.rowsPerPage,
        this.pagination.page,
        this.searchData
      );
      if (res.data.code == "000") {
        res.data.results.forEach((item) => {
          item.bannedStatus == 0
            ? (item.bannedStatus = "正常")
            : item.bannedStatus == 1
            ? (item.bannedStatus = "警告")
            : item.bannedStatus == 2
            ? (item.bannedStatus = "封禁")
            : (item.bannedStatus = "---");
          item.online == 0
            ? (item.online = "在线")
            : item.online == 1
            ? (item.online = "离线")
            : item.online == 2
            ? (item.online = "登出")
            : (item.online = "---");
        });
        this.datas = res.data.results;
        this.pagination.total = res.data.totalCount;
      }
    },
    sizechange(obj) {
      this.pagination.rowsPerPage = obj.rowsPerPage;
      this.getUserListApi();
    },
    detail(row) {
      this.getBSuserById(row.id);
      this.Dialog.visible = true;
    },
    async getBSuserById(id) {
      let res = await getBSuserById({ userId: id });
      this.userType=res.data.result.userType
      if (res.data.code == "000") {
        res.data.result.userType == 0
          ? (res.data.result.userType = "普通")
          : res.data.result.userType == 1
          ? (res.data.result.userType = "专家")
          : res.data.result.userType == 2
          ? (res.data.result.userType = "倾听者")
          : res.data.result.userType == 9
          ? (res.data.result.userType = "管理员")
          : "";
        res.data.result.bannedStatus == 0
          ? (res.data.result.bannedStatus = "正常")
          : res.data.result.bannedStatus == 1
          ? (res.data.result.bannedStatus = "警告")
          : res.data.result.bannedStatus == 2
          ? (res.data.result.bannedStatus = "封禁")
          : "";
        res.data.result.online == 0
          ? (res.data.result.online = "在线")
          : res.data.result.online == 1
          ? (res.data.result.online = "离线")
          : res.data.result.online == 2
          ? (res.data.result.online = "登出")
          : "";
        this.detailInfo = res.data.result;
      }
    },
    onClose() {
      this.Dialog.visible = false;
    },
    warnBtn() {
      this.$prompt("确定警告该用户？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(async (value) => {
          if (!value.value) {
            Message({
              type: "warning",
              message: "警告理由不能为空",
            });
            return;
          } else {
                 let obj = {
              userId: this.detailInfo.id,
              userType: Number(this.userType),
              type: 1,
              content: value.value,
            };
            let res = await operate(obj);
            if (res.data.code == "000") {
              this.getUserListApi();
              this.Dialog.visible=false
            }
            Message({
              type: "success",
              message: "警告成功!",
            });
          }
        })
        .catch(() => {
          Message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    banBtn() {
      this.$prompt("确定封禁该用户？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(async (value) => {
          if (!value.value) {
            Message({
              type: "warning",
              message: "封禁理由不能为空",
            });
            return;
          } else {
            let obj = {
              userId: this.detailInfo.id,
              userType: Number(this.userType),
              type: 2,
              content: value.value,
            };
            let res = await operate(obj);
            if (res.data.code == "000") {
              this.getUserListApi();
              this.Dialog.visible=false
              Message({
                type: "success",
                message: "封禁成功!",
              });
            }
          }
        })
        .catch(() => {
          Message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    unsealBtn() {
      this.$confirm("解封该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async() => {
                let obj = {
              userId: this.detailInfo.id,
              userType: Number(this.userType),
              type: 3,
              content: "",
            };
            let res = await operate(obj);
            if (res.data.code == "000") {
              this.getUserListApi();
              this.Dialog.visible=false
            }
            Message({
              type: "success",
              message: "解封成功!",
            });
        })
        .catch(() => {
          Message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    editBtn() {
      this.$prompt("修改余额？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue:this.detailInfo.balance,
      })
        .then(async (value) => {
          if (!value.value) {
            Message({
              type: "warning",
              message: "修改余额不能为空",
            });
            return;
          } else {
          let obj = {
              userId: this.detailInfo.id,
              gole: value.value,
              type:1,
            };
            let res = await species(obj);
            if(res.data.code=="000"){
             this.getUserListApi();
              this.Dialog.visible=false
            Message({
              type: "success",
              message: "修改余额成功!",
            });
            }
          }
        })
        .catch(() => {
          Message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    goldBtn() {
      this.$prompt("修改金币？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
          inputValue:this.detailInfo.gold,
      })
        .then(async (value) => {
          if (!value.value) {
            Message({
              type: "warning",
              message: "修改金币不能为空",
            });
            return;
          } else {
            let obj = {
              userId: this.detailInfo.id,
              gole: value.value,
              type:0,
            };
            let res = await species(obj);
             if(res.data.code=="000"){
             this.getUserListApi();
              this.Dialog.visible=false
            Message({
              type: "success",
              message: "修改金币成功!",
            });
             }
          }
        })
        .catch(() => {
          Message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
  },
  //生命周期 - 挂载前
  created() {
    this.getUserListApi();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang='scss' scoped>
.container {
  width: 100%;
  height: 100%;
}
.el-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.el-row {
  margin-bottom: 30px;
  font-size: 16px;
}
.foot_box {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
</style>

