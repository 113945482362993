<template>
  <div>
    <div>
      <filterSearch
        ref="filter"
        class="gas_order_filter"
        :data="filterData"
        @serach="search"
      />
    </div>
    <vueTable
      ref="table"
      :headers="headers"
      :rows="datas"
      :selection="false"
      :pagination="pagination"
      :isPage="true"
      :index="true"
      hasScroll
      class="table"
      @sizechange="sizechange"
    >
    </vueTable>
  </div>
</template>
<script>
//import引入的组件
import { getListenApi } from "../../api/listen";
export default {
  components: {
    vueTable: () => import("../../components/table/table.vue"),
    filterSearch: () => import("../../components/table/table_filter.vue"),
  },
  data() {
    return {
      filterData: [
        {
          label: "倾听者名",
          type: "input",
          key: "audientName",
          placeholder: "请输入倾听者名",
        },
        {
          label: "倾听者手机号",
          type: "input",
          key: "phoneNum",
          placeholder: "请输入倾听者手机号",
        },
      ],
      searchData: {
 
      },
      headers: [
        {
          prop: "audientName",
          label: "倾听者名",
          width: "",
        },

        {
          prop: "phoneNum",
          label: "倾听者手机号",
          width: "",
        },
        {
          prop: "price",
          label: "倾听者价格",
          width: "",
        },
        {
          prop: "ordertimes",
          label: "接单次数",
          width: "",
        },
        {
          prop: "bannedStatus",
          label: "账号状态",
          width: "",
        },
      {
          prop: "updateDate",
          label: "活跃时间",
          width: "",
        },
        {
          prop: "online",
          label: "在线状态",
          width: "",
        },
        {
          label: "操作",
          width: 220,
          type: "operations",
        },
      ],
      datas: [{}],
      pagination: {
        page: 1,
        rowsPerPage: 10,
        total: 0,
      },
    };
  },
  //方法集合
  methods: {
    search(value) {
      this.searchData.audientName = value.audientName;
      this.searchData.phoneNum = value.phoneNum;
      (this.pagination.rowsPerPage = 10), (this.pagination.page = 1);
      this.getListenApi();
    },
    //获取用户信息
    async getListenApi() {
      let res = await getListenApi(
        this.pagination.rowsPerPage,
        this.pagination.page,
      this.searchData
      );
    if(res.data.code=="000"){
   res.data.results.forEach(item => {
            item.bannedStatus==0?item.bannedStatus='正常':item.bannedStatus==1?item.bannedStatus='警告':item.bannedStatus==2?item.bannedStatus='封禁':item.bannedStatus="---"
            item.online==0?item.online='在线':item.online==1?item.online='离线':item.online==2?item.online='登出':item.online="---"
            
          });
        this.datas=res.data.results
        this.pagination.total=res.data.totalCount
    }
    },
    sizechange(obj) {
      this.pagination.rowsPerPage = obj.rowsPerPage;
      this.getListenApi();
    },
  },
  //生命周期 - 挂载前
  created() {
    this.getListenApi();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang='scss' scoped>
</style>

