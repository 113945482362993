<template>
  <div class="logincontiner">
    <el-form
      class="loginForm"
      :model="loginForm"
      ref="loginForm"
      :inline="false"
      size="medium"
      :rules="loginRules"
    >
      <el-form-item>
        <div class="loginTitle">系统登录</div>
      </el-form-item>
      <el-form-item prop="username">
        <el-input
          v-model="loginForm.name"
          placeholder="请输入用户名"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          type="password"
          v-model="loginForm.pass"
          placeholder="请输入密码"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-button class="btn" @click="commitBtn" type="primary"
              >登录</el-button
            >
          </el-col>
          <!-- <el-col :span="12">
            <el-button class="btn" @click="resetBtn" type="primary"
              >重置</el-button
            >
          </el-col> -->
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { login } from "../api/login";
// import {getImageApi} from '../api/user'
// import Cookies from "js-cookie";
import { setToken, setUserId } from "../utils/auth";
export default {
  data() {
    return {
      //验证码
      imgSrc:"",
      loginForm: {
        name: "",
        pass: "",
      },
      loginRules: {
        name: [
          {
            required: true,
            trigger: "change",
            message: "请输入用户名",
          },
        ],
        pass: [
          {
            required: true,
            trigger: "change",
            message: "请输入密码",
          },
        ],
        code: [
          {
            required: true,
            trigger: "change",
            message: "请输入验证码",
          },
        ],
      },
    };
  },
  created(){
    // this.getImage();
  },
  methods: {
    //获取验证
    //返回的数据是arraybuffer,需要转换为base64给img
    //1.arraybuffer转换为二进制
    //2.把二进制字符转换为base64字符
    commitBtn() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          let res = await login(this.loginForm);
          if (res.data.code == "000") {
            // let datas = res.data;
            // //设置token到cookies里面
            setToken(res.data.result.token);
            // //设置用户id到cookies里面
            // setUserId(datas.id);
            this.$router.push({
              path:"/home"
            });
          } else {
            this.$message.error(res.data.msg);
            return;
          }
        }
      });
    },
    resetBtn() {
      this.$refs["loginForm"].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.logincontiner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .loginForm {
    height: 250px;
    width: 350px;
    box-shadow: 0 0 25px #cac6c6;
    border-radius: 10px;
    padding: 20px 35px;
    .loginTitle {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 24px;
    }
    .btn {
      width: 100%;
    }
  }
}
.images{
  height: 36px;
  width: 100px;
}
</style>