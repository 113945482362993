<template>
  <div ref="container" class="show_more_filter">
    <slot></slot>

    <a
      v-if="showBtn"
      class="show_more_btn"
      :style="{
        left: btnLeft,
      }"
      @click="toggle"
    >
      {{ showMore ? "收起" : "更多" }}
      <i
        data-v-3b31b76f=""
        :class="showMore ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"
      ></i>
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showBtn: false,
      showMore: false,
      btnLeft: 0,
    };
  },
  mounted() {
    this.resize();
    window.addEventListener("resize", this.resize);
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    resize() {
      this.$nextTick(() => {
        let container = this.$refs.container;
        let containerWidth = container.getBoundingClientRect().width - 80;
        let filters = container.querySelectorAll(".filter_item");
        let totalWidth = 0;
        let wrapFlag = false;

        this.showBtn = false;

        filters.forEach((filter, index) => {
          filter.style.display = "inline-block";

          let filterWidth =
            Math.ceil(filter.getBoundingClientRect().width) + 15;

          totalWidth += filterWidth;

          if (totalWidth > containerWidth) {
            this.showBtn = true;
            if (!wrapFlag) {
              this.btnLeft = totalWidth - filterWidth + 5 + "px";
              wrapFlag = true;
            }

            filter.style.display = this.showMore ? "inline-block" : "none";
          } else {
            filter.style.display = "inline-block";
          }
        });
      });
      this.$emit("setHeight", this.showMore)
    },
    toggle() {
      this.showMore = !this.showMore;
      this.resize();
    },
  },
};
</script>

<style scoped>
.show_more_filter {
  position: relative;
  padding-right: 80px;
}
.show_more_btn {
  position: absolute;
  top: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #8890a7;
  cursor: pointer;
  user-select: none;
      right: 0px
}
</style>
