<template>
  <div>
    <div>
      <filterSearch
        ref="filter"
        class="gas_order_filter"
        :data="filterData"
        @serach="search"
        @onDelete="onDelete"
        :isDel="true"
      />
    </div>
    <vueTable
      ref="table"
      :headers="headers"
      :rows="datas"
      :selection="false"
      :pagination="pagination"
      :isPage="true"
      :index="true"
      hasScroll
      class="table"
      @sizechange="sizechange"
    >
    </vueTable>
  </div>
</template>
<script>
//import引入的组件
// import VueTable from "../../../components/table/table.vue";
// import tableFilter from "../../../components/table/table_filter.vue";
import { Message } from "element-ui";
import { getChatListApi, truncate } from "../../../api/chat";
export default {
  components: {
    vueTable: () => import("../../../components/table/table.vue"),
    filterSearch: () => import("../../../components/table/table_filter.vue"),
  },
  data() {
    return {
      filterData: [
        {
          label: "发送方",
          type: "input",
          key: "sendName",
          placeholder: "请输入发送方",
        },
        {
          label: "接收方",
          type: "input",
          key: "receiveName",
          placeholder: "请输入接收方",
        },
         {
          label: "内容",
          type: "input",
          key: "content",
          placeholder: "请输入内容",
        },
      ],
      searchData: {
        sendName: "",
        receiveName: "",
        content: "",
      },
      headers: [
        {
          prop: "sendName",
          label: "发送方",
          width: "",
        },
        {
          prop: "receiveName",
          label: "接收方",
          width: "",
        },
        {
          prop: "content",
          label: "内容",
          width: "",
        },
        {
          prop: "sentTime",
          label: "时间",
          width: "",
        },
      ],
      datas: [{}],
      pagination: {
        page: 1,
        rowsPerPage: 10,
        total: 0,
      },
    };
  },
  //方法集合
  methods: {
    async onDelete() {
      let res = await truncate();
      if (res.data.code == "000") {
        Message({
          type: "success",
          message: "删除成功!",
        });
        this.getChatListApi();
      }
    },
    search(value) {
      this.searchData.sendName = value.sendName;
      this.searchData.receiveName = value.receiveName;
       this.searchData.content = value.content;
      (this.pagination.rowsPerPage = 10), (this.pagination.page = 1);
      this.getChatListApi();
    },
    //获取用户信息
    async getChatListApi() {
      let res = await getChatListApi(
        this.pagination.rowsPerPage,
        this.pagination.page,
        this.searchData
      );
      if (res.data.code == "000") {
        this.datas = res.data.results;
        this.pagination.total = res.data.totalCount;
      }
    },
    sizechange(obj) {
      this.pagination.rowsPerPage = obj.rowsPerPage;
      this.getChatListApi();
    },
  },
  //生命周期 - 挂载前
  created() {
    this.getChatListApi();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang='scss' scoped>
</style>

