<template>
  <div class="inline_form_fliter">
    <ShowMoreFilter
      ref="filter_list"
      class="filter_list"
      @showitem="showitem"
      @setHeight="setHeight"
    >
      <div
        class="filter_item"
        v-for="(item, index) in data"
        :key="'filter_' + index"
      >
        <span v-if="item.label && item.label != ''" class="filter_title"
          >{{ item.label }}：</span
        >
        <!-- <span v-else class="filter_title">&nbsp;&nbsp;&nbsp;&nbsp;</span> -->
        <el-input
          v-if="item.type == 'input'"
          v-model="formInline[item.key]"
          :placeholder="item.placeholder"
        ></el-input>
        <el-date-picker
          v-else-if="item.type == 'date'"
          v-model="formInline[item.key]"
          :type="item.type2"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :placeholder="item.placeholder"
          :default-time="item.defaultime"
          :value-format="item.format || 'yyyy-MM-dd HH:mm:ss'"
        ></el-date-picker>
        <el-select
          v-if="item.type == 'select'"
          :multiple="item.multiple || false"
          v-model="formInline[item.key]"
          :placeholder="item.placeholder"
          :filterable="item.filterable"
        >
          <el-option
            v-for="(it, idx) in item.arr"
            :key="idx + 600"
            :label="item.labelname ? it[item.labelname] : it.label"
            :value="item.value ? it[item.value] : it.value"
          ></el-option>
        </el-select>
        <el-select
          style="width: 150px"
          v-if="item.type == 'select1'"
          :multiple="item.multiple || false"
          v-model="formInline[item.key]"
          :placeholder="item.placeholder"
          :filterable="item.filterable"
          @change="getValue(item)"
        >
          <el-option
            v-for="(it, idx) in item.arr"
            :key="idx + 600"
            :label="item.labelname ? it[item.labelname] : it.label"
            :value="item.value ? it[item.value] : it.value"
          ></el-option>
        </el-select>
        <el-cascader
          v-if="item.type == 'cascader'"
          v-model="formInline[item.key]"
          :options="item.arr"
          :placeholder="item.placeholder"
          :props="item.props || {}"
          :popper-class="item.popperClass"
        ></el-cascader>
        <el-radio-group
          v-if="item.type == 'radio'"
          v-model="formInline[item.key]"
        >
          <el-radio
            v-for="(item, index) in item.arr"
            :key="index"
            :label="item.value === undefined ? index : item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
        <el-checkbox-group
          v-if="item.type == 'checkbox'"
          v-model="formInline[item.key]"
        >
          <el-checkbox
            v-for="(item, index) in item.arr"
            :key="index"
            :label="item.value"
          >
            {{ item.label }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
    </ShowMoreFilter>
    <div class="filter_action">
      <el-button type="info" size="medium" @click="remove_data">重置</el-button>
      <el-button type="primary" size="medium" @click="onSubmit">查询</el-button>
      <el-button type="warning" size="medium" v-if="isDel" @click="onDelete"
        >删除</el-button
      >
      <el-button type="warning" size="medium" v-if="isCount" @click="onCount"
        >推广收益计算</el-button
      >
      <!-- <el-divider
        direction="vertical"
        class="divider_h"
        v-if="sdvancedSearch"
      ></el-divider>
      <el-button
        class="filter-btn advanced_btn"
        type="primary"
        @click="showAdvancedScreening"
        v-if="sdvancedSearch"
        >高级筛选</el-button
      > -->
    </div>
  </div>
</template>

<script>
import ShowMoreFilter from "@/components/table/show_more_filter";
// import DefaultForm from "../../components/form/default_form.vue";
// import DefaultDialog from "@/components/form/default_dialog";
export default {
  props: {
    data: {
      type: Array,
      default: () => {
        [];
      },
    },
    isDel: {
      type: Boolean,
      default: () => false,
    },
    isCount: {
      type: Boolean,
      default: () => false,
    },
    advanced_data: {
      type: Array,
      default: () => {
        [];
      },
    },
    sdvancedSearch: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    ShowMoreFilter,
    // DefaultDialog,
    // DefaultForm,
  },
  data() {
    return {
      formInline: {},
      dialogAdvancedScreening: false,
    };
  },
  watch: {},
  computed: {},
  created() {
    var temp = {};
    this.data.forEach((e) => {
      if (e.type == "checkbox") {
        temp[e.key] = [];
      } else if (e.type == "radio") {
        temp[e.key] = e.arr[0].value;
      } else {
        temp[e.key] = "";
      }
    });
    this.formInline = temp;
    // this.$emit("serach", this.formInline);
  },
  mounted() {},
  methods: {
    onSubmit() {
      let obj = this.formInline;
      this.$emit("serach", obj);
    },
    onDelete() {
      this.$emit("onDelete");
    },
    onCount() {
      this.$emit("onCount");
    },
    remove_data() {
      for (var index in this.formInline) {
        this.formInline[index] = "";
      }
      var temp = {};
      this.data.forEach((e) => {
        temp[e.key] = "";
      });
      // temp.reset = "重置";
      this.$emit("serach", temp);
    },
    removedate() {
      for (var index in this.formInline) {
        this.formInline[index] = "";
      }
    },
    dialogSubmitjs() {
      let obj = this.$refs.advanced.formInline;
      this.$emit("serach", obj);
      console.log(obj, "obj");
      this.$refs.dialogAdvancedScreening.hide();
    },
    dialogCancelcz() {
      this.$refs["advanced"].remove_data();
      var temp = {};
      this.data.forEach((e) => {
        temp[e.key] = "";
      });
      temp.reset = "重置";
      this.$emit("serach", temp);
    },
    search() {
      this.$emit("serach", "");
    },
    // handleClose(done) {
    //   this.$confirm("确认关闭？")
    //     .then((_) => {
    //       done();
    //     })
    //     .catch((_) => {});
    // },
    showAdvancedScreening() {
      this.$refs.dialogAdvancedScreening.show();
    },
    detailDialogToggle() {},
    showitem() {
      this.$emit("showitem");
      this.$refs.filter_list.resize();
    },
    setHeight(val) {
      this.$emit("setHeight", val);
    },
    onDownLoad() {
      this.$emit("downLoadList");
    },
    getValue(formItem) {
      if (formItem.fun) {
        formItem.fun(this.formInline[formItem.key]);
        formItem.resetKey.forEach((tem) => {
          this.data.forEach((item) => {
            if (tem == item.key) {
              this.formInline[item.key] = "";
            }
          });
        });
      }
    },
  },
};
</script>

<style scoped>
.inline_form_fliter {
  padding: 16px 16px 0;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  margin-bottom: 16px;
  box-shadow: 0px 3px 6px 0px rgba(15, 19, 65, 0.08);
}
.inline_form_fliter .filter_list {
  flex-grow: 1;
  min-width: 550px;
  font-size: 0;
}
.filter_list > .filter_item {
  display: inline-block;
  vertical-align: middle;
  /* margin-top: 20px; */
}
.filter_list > .filter_item {
  margin-right: 15px;
  margin-bottom: 16px;
}
.filter_list > .filter_item > * {
  display: inline-block;
  vertical-align: middle;
}
.filter_list > .filter_item > .filter_title {
  /* width: 8em; */
  text-align: right;
  font-size: 14px;
  font-weight: 700;
  color: #242c43;
  margin-right: 10px;
  white-space: nowrap;
}
/* .filter_list > .filter_item > .filter_title + * {
  width: 200px;
} */
.filter_list > .filter_item .el-input {
  width: 240px;
}
.inline_form_fliter .filter_action {
  flex-shrink: 0;
  margin-left: 25px;
  text-align: right;
}
.divider_h {
  height: 2rem;
  margin: 0 12px;
}
.filter-btn {
  display: inline-block;
  width: 92px;
  height: 36px;
  background-color: #e3e5ef;
  border-radius: 4px;
  border: none;
}
.filter-btn-query {
  background-color: #0f2683;
}
.filter-btn-reset {
  color: #8890a7;
}
.advanced_btn {
  background-color: #242c43;
}
::v-deep .el-date-editor .el-range-separator {
  width: 24px;
}
</style>

