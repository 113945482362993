
module.exports.GetProperty = function (obj, prop) {
  if (!obj) return null;
  let res = obj;
  if (prop) {
    let props = prop.split('.');
    for (let i = 0; i < props.length; i++) {
      res = res[props[i]];
      if (typeof res == "undefined" || res == null) {
        return null;
      }
    }
  }
  return res;
}

module.exports.dateFormat = function (fmt, timestamp) {
  let date = new Date(timestamp);
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(),        // 年
    "m+": (date.getMonth() + 1).toString(),     // 月
    "d+": date.getDate().toString(),            // 日
    "H+": date.getHours().toString(),           // 时
    "M+": date.getMinutes().toString(),         // 分
    "S+": date.getSeconds().toString()          // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
    }
  }
  return fmt;
}

module.exports.dateStringTransform = function (date) {
  /* 
    input: 2020-06-12T16:38:11+08:00
    output: 2020-06-12 16:38:11
  */
  if (String(date).indexOf("T") != -1) {
    let arr = date.split("T");
    let t = arr[1];
    let tarr = t.split('.000');
    let marr = tarr[0].split(':');
    let h = parseInt(marr[0]) >= 10 ? parseInt(marr[0]) : "0" + parseInt(marr[0]);
    let m = parseInt(marr[1]) >= 10 ? parseInt(marr[1]) : "0" + parseInt(marr[1]);
    let s = parseInt(marr[2]) >= 10 ? parseInt(marr[2]) : "0" + parseInt(marr[2]);
    let dd = arr[0] + " " + h + ":" + m + ":" + s;
    return dd;
  } else {
    return date;
  }
}

module.exports.downloadFileFormat = function (fileUrl) {
  /* 
    input: /apaas/static/docs/image/images/1234.png
    output: 1234.png
  */
  if (fileUrl != "") {
    return fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
  } else {
    return "";
  }
}

module.exports.numberFormat = function (num, decimals) {
  /* 
    input:  10000 1000
    output: 10    1000
  */
  if (num > 10000) {
    return Math.floor(num / 10000).toFixed(decimals);
  } else {
    return num;
  }
}

module.exports.CreationDateDesc = function (timestamp) {
  let date = new Date(timestamp);
  return date.toLocaleDateString("zh-CN", {
    timeZone: "Asia/Shanghai"
  });
}

module.exports.getQueryString = function (name, url) {
  let search = url.substr(url.indexOf("?"));
  let theRequest = new Object();
  if (search.indexOf("?") != -1) {
    let str = search.substr(1);
   let  strs = str.split("&");
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
    }
  }
  return theRequest[name];
}

// 清除cookie
module.exports.clearCookie = function (name) {
  setCookie(name, "", -1);
}

// 设置cookie
function setCookie(name, value, seconds) {
  seconds = seconds || 0;   //seconds有值就直接赋值，没有为0，这个根php不一样。
  var expires = "";
  if (seconds != 0) {      //设置cookie生存时间
    var date = new Date();
    date.setTime(date.getTime() + (seconds * 1000));
    expires = "; expires=" + date.toGMTString();
  }
  document.cookie = name + "=" + escape(value) + expires + "; path=/";   //转码并赋值
}
module.exports.getCookie = function (name) {
  var strCookie = document.cookie;
  var arrCookie = strCookie.split("; ");
  for (var i = 0; i < arrCookie.length; i++) {
    var arr = arrCookie[i].split("=");
    if (arr[0] == name) return arr[1];
  }
  return "";
}

//下载模板
module.exports.downloadFunction = function (url) {
  const a = document.createElement("a"); // 创建a标签
  a.setAttribute("download", ""); // download属性
  a.setAttribute("href", url); // href链接
  a.click(); // 自执行点击事件
}