import http from '../http/request'
//获取用户列表
export async function getUserListApi(pageSize,currentPage,params) {
    return await http.post(`/backStage/getBSuserList/${pageSize}/${currentPage}`, params)
}
//新增用户
export async function getBSuserById(params) {
    return await http.get("/backStage/getBSuserById", params)
}
//用户封禁解封警告
export async function operate(params) {
    return await http.get("/backStage/operate", params)
}
//修改余额金币
export async function species(params) {
    return await http.get("/backStage/species", params)
}
//注销用户
export async function getBSoutListApi(pageSize,currentPage,params) {
    return await http.post(`/backStage/getBSoutList/${pageSize}/${currentPage}`, params)
}
// //编辑用户
// export async function editUserApi(params) {
//     return await http.put("/api/user", params)
// }
// //删除用户
// export async function deleteUserApi(params) {
//     return await http.delete("/api/user", params)
// }
// //获取分配角色列表
// export async function getRolistForAssignApi(params) {
//     return await http.get("/api/user/getRolistForAssign", params)
// }
// //获取被分配用户的角色id
// export async function getRoleIdByUserIdApi(params) {
//     return await http.getRestApi("/api/user/getRoleIdByUserId", params)
// }
// //分配角色保存
// export async function assignSaveApi(params) {
//     return await http.post('/api/user/assingRole', params)
// }
// //获取验证码
// export async function getImageApi() {
//     return await http.getImage("/api/sysUser/image")
// }
// //退出登录
// export async function loginOut(parm) {
//     return await http.post("/api/sysUser/loginOut", parm)
// }